import { useTranslation } from "react-i18next";

const printStyles = `
    @media print {
      @page {
        size: A4 portrait;
      }
      body {
        background: white !important;
        box-shadow: none !important;
      }
      table, figure {
        page-break-inside: avoid;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }
      th {
        border-bottom: 2px solid #dcdcdc;
        padding: 8px;
      }
      td {
        border-bottom: 1px solid #dcdcdc;
        padding: 8px;
      }
      thead {
        font-weight: bold;
      }
      .no-print {
        display: none;
      }
      .hide-screen {
        display: block
      }
    }
  `;

const PrintableTable = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <style>{printStyles}</style>
      <table>
        <thead>
          <tr>
            <th>{t("storageLocation")}</th>
            <th style={{ width: "20%" }}></th>
            <th>{t("serialNumber")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.storage_location}</td>
              <td></td>
              <td>{item.product_number}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PrintableTable;
