import React from 'react';
import PropTypes from 'prop-types';
import { Card as BootstrapCard } from "reactstrap";
import './Card.css';

export const Card = ({ variant, className, children, ...rest }) => {
  let extendClassName = undefined;
  if (variant === "primary")
  {
    extendClassName = "bg-secondary mb-0 border border-1 border-light rounded-2"
  }
  return (
    <BootstrapCard
      className={`${extendClassName ? extendClassName : ""} ${className ? className : ""}`}
      {...rest}
    >
      {children}
    </BootstrapCard>
  );
};

Card.propTypes = {
  variant: PropTypes.oneOf(['default','primary']),
};

Card.defaultProps = {
  
};
