import { useEffect, useState } from "react";
import { useAuthContext } from "contexts/AuthContext";
import history from "services/history";

import { useTranslation } from "react-i18next";

import Loader from "components/project/Loader";
import { httpGetUsers } from "services/users";
import { Button, Typography } from "stories/components";
import { Row } from "stories/layout";
import ChangePassword from "components/project/ChangePassword";
import AddNewUser from "components/project/AddNewUser";
import SortableTable from "components/project/SortableTable";
import DeleteUser from "components/project/DeleteUser";
import ReactSelect from "react-select";
import RoleChange from "components/project/RoleChangeModal";

const AdminView = () => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();
  const [addUsermodal, setAddUserModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [roleChangeModal, setRoleChangeModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (myUser?.status !== 10) {
      history.push("/dashboard/");
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const roleList = [
    {
      id: 1,
      role: t("userDeliverOrders"),
    },
    {
      id: 2,
      role: t("userCancelOrders"),
    },
    {
      id: 10,
      role: t("Admin"),
    },
  ];

  // useEffect(() => {
  //   console.log("selectedRole", selectedRole);
  //   toggleRoleChange();
  // }, [selectedRole]);

  const toggleAddUser = () => setAddUserModal(!addUsermodal);

  const toggleDeleteUser = (user) => {
    setSelectedUser(user);
    setUserDeleteModal(!userDeleteModal);
  };

  const togglePasswordModal = (user) => {
    setSelectedUser(user);
    setPasswordModal(!passwordModal);
  };

  const handleRoleChange = (user, role) => {
    setSelectedUser(user);
    setSelectedRole(role);
    setRoleChangeModal(true);
  };

  const toggleRoleChange = () => {
    setRoleChangeModal(!roleChangeModal);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await httpGetUsers();

      const filteredData = response.data
        // .filter((u) => u.status !== 10)
        .filter((u) => !u.is_staff);
      setUsers(filteredData);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      key: "email",
      label: t("username"),
      sortable: false,
      format: (item) => item.email,
      width: "30%",
    },
    {
      key: "role",
      label: t("role"),
      sortable: false,
      format: (item) => (
        <ReactSelect
          value={roleList.find((role) => role.id === item.status)}
          options={roleList}
          getOptionValue={(option) => `${option["id"]}`}
          getOptionLabel={(option) => `${option["role"]}`}
          onChange={(event) => handleRoleChange(item, event)}
          menuPortalTarget={document.body}
        />
      ),
      width: "30%",
    },
    {
      key: "actions",
      label: "",
      sortable: false,
      format: (item) => (
        <div className="d-flex justify-content-end">
          <Button className="mx-1" onClick={() => togglePasswordModal(item)}>
            <Typography bold>{t("changePassword")}</Typography>
          </Button>
          <Button variant="danger" onClick={() => toggleDeleteUser(item)}>
            <Typography bold>{t("deleteUser")}</Typography>
          </Button>
        </div>
      ),
      width: "40%",
    },
  ];

  useEffect(() => {
    console.log("roleChangeModal", roleChangeModal);
  }, [roleChangeModal]);

  return (
    <div className="container-background pb-5">
      <Row className="d-flex justify-content-center item-padding">
        <div className="d-flex justify-content-end mb-2">
          <Button onClick={toggleAddUser}>
            <Typography bold>{t("addNewUser")}</Typography>
          </Button>
        </div>
        {loading && <Loader />}
      </Row>

      <SortableTable columns={columns} data={users} />

      {/* Modals */}
      <AddNewUser
        show={addUsermodal}
        toggle={toggleAddUser}
        fetchUsers={fetchUsers}
      />

      <ChangePassword
        user={selectedUser}
        show={passwordModal}
        toggle={togglePasswordModal}
      />

      <DeleteUser
        user={selectedUser}
        show={userDeleteModal}
        toggle={toggleDeleteUser}
        fetchUsers={fetchUsers}
      />

      <RoleChange
        user={selectedUser}
        role={selectedRole}
        show={roleChangeModal}
        toggle={toggleRoleChange}
        fetchUsers={fetchUsers}
      />
    </div>
  );
};

export default AdminView;
