import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { Typography } from "stories/components";

const EditModal = ({ isOpen, toggle, onSave, initialData }) => {
  const { t } = useTranslation();
  const [storageLocation, setStorageLocation] = useState("");
  const [productNumber, setProductNumber] = useState("");

  useEffect(() => {
    if (initialData) {
      setStorageLocation(initialData.storage_location || "");
      setProductNumber(initialData.product_number || "");
    }
  }, [initialData]);

  const handleSave = () => {
    onSave({ id: initialData.id, storageLocation, productNumber });
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Typography variant="h3">{t("editItem")}</Typography>
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <Typography bold>{t("serialNumber")}</Typography>
          <Input
            type="text"
            value={productNumber}
            onChange={(e) => setProductNumber(e.target.value.toUpperCase())}
          />
        </div>
        <div>
          <Typography bold>{t("storageLocation")}</Typography>
          <Input
            type="text"
            value={storageLocation}
            onChange={(e) => setStorageLocation(e.target.value.toUpperCase())}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" onClick={handleSave}>
          <Typography bold className="">
            {t("Save")}
          </Typography>
        </Button>
        <Button color="warning" onClick={toggle}>
          <Typography bold className="text-white">
            {t("Cancel")}
          </Typography>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditModal;
