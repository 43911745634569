import React from "react";
import {
  Breadcrumb as BootstrapBreadcrumb,
  BreadcrumbItem as BootstrapBreadcrumbItem,
} from "reactstrap";

export const Breadcrumb = ({ children, ...rest }) => {
  return <BootstrapBreadcrumb {...rest}>{children}</BootstrapBreadcrumb>;
};

export const BreadcrumbItem = ({ children, ...rest }) => {
  return (
    <BootstrapBreadcrumbItem {...rest}>{children}</BootstrapBreadcrumbItem>
  );
};

/*

<Breadcrumb listClassName="breadcrumb-links">
  <BreadcrumbItem>
    <a href="#pablo" onClick={e => e.preventDefault()}>
      Tavaratilaukset
    </a>
  </BreadcrumbItem>
  <BreadcrumbItem>
    <a aria-current="page" className="active">
      {getTabName()}
    </a>
  </BreadcrumbItem>
  <BreadcrumbItem>
    <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
      {getTabName()}
    </Link>
  </BreadcrumbItem>
  
</Breadcrumb>
      
*/
