import React, { useEffect, useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';

// core components
import { Input } from "stories/forms";
import { Button, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { useAuthContext } from "contexts/AuthContext";
import { httpChangePassword } from "services/users";
import logo from "assets/images/logo_nav.png"

const Invitation = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <div className="d-flex justify-content-center">
          <img src={logo} style={{width: "300px"}} />
        </div>
        
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('invitation_set_new_password','Aseta uusi salasana')}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("new_password")}
                      label={t('invitation_new_password','Uusi salasana')}
                      type="password"
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("check_password")}
                      label={t('invitation_check_password','Salasana uudelleen')}
                      type="password"
                    />
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t('Tallenna')}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  id: null,
}

 const InvitationFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("required_field","Kenttä on pakollinen");
      
      return Yup.object().shape({
        new_password: Yup.string().required(required).nullable(),
        check_password: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        data: {
          new_password: values?.new_password,
          check_password: values?.check_password,
        }
      };
      
      httpChangePassword("me", data).then(res => {
        setSubmitting(false);
        if (res?.status === 200) {
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("accessToken");
          props.notify({ title:t("invitation_password_title","Salasana"), message:t("invitation_password_update_ok","Päivitetty onnistuneesti")})
          // window.location.href = "/auth/login"
          props.history.push("/auth/login")
        }
        else {
          setErrors({
            general: JSON.stringify(res?.data),
          })
        }
        
        
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Invitation)
    
    
const InvitationView = ({location, match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { setLanguage } = useAuthContext();
  
  useEffect(() => {
    setLanguage("en");
  }, [])
  
  useEffect(() => {
    
    const parsed = queryString.parse(location.search);
    if (parsed?.token) {
      localStorage.removeItem("refreshToken");
      localStorage.setItem("accessToken", parsed?.token);
    }
  },[location])
  
  return (
    <>
      <InvitationFormik history={history} t={t} notify={notify} />
    </>
  )
}
  

export default InvitationView;
