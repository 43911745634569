import { createStore } from "easy-peasy";
import authModel from "./auth-model";
import categoriesModel from "./categories-model";
import cookiesModel from "./cookies-model";
import orderCountModel from "./orderCount-model";

export const store = createStore({
  auth: authModel,
  categories: categoriesModel,
  cookies: cookiesModel,
  orderCount: orderCountModel,
});
