import React from "react";
// import { NavItem, NavLink } from "reactstrap";

const NavbarLinks = () => {
  return (
    <>
      {/* <NavItem>
        <NavLink className="text-white mx-1" href="*">example</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="text-white mx-1" href="*">example</NavLink>
      </NavItem> */}
    </>
  );
};

export default NavbarLinks;
