import React, { useCallback, useState, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// core components
import { Input } from "stories/forms";
import { Button, Typography } from "stories/components";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "stories/layout";
import { useNotification } from "stories/components/Notification";

import { httpLogin } from "services/auth";
// import { httpSendActivationEmail } from "services/users";
// import { useStoreActions } from "easy-peasy";
import { useAuthContext } from "contexts/AuthContext";
import { Spinner } from "reactstrap";

const Login = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    // isValid,
    isSubmitting,
    // setErrors,
    // setSubmitting,
    validateForm,
  } = props;

  const { t } = props;

  const getFormikInputProps = useCallback(
    (name) => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange,
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

  // const handleResendActivationEmail = (emailAdress) => {
  //   setSubmitting(true);
  //   httpSendActivationEmail(emailAdress).then((res) => {
  //     notify({
  //       title: t("Activation"),
  //       message: t("Activation link sent successfully to your email"),
  //     });
  //     setErrors({});
  //     setSubmitting(false);
  //   });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(() => handleSubmit());
  };

  return (
    <>
      <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="secondary">
              <CardHeader className="pt-5 pb-1">
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">
                    {t("Login", "Kirjaudu")}
                  </Typography>
                  {/*<Typography variant="p">{t('Tervetuloa takaisin')}</Typography>*/}
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={onSubmit}>
                  <Row>
                    <Col className="mb-3">
                      <Input
                        {...getFormikInputProps("email")}
                        label={t("Email")}
                        type="email"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Input
                        {...getFormikInputProps("password")}
                        label={t("Password")}
                        type="password"
                        required
                      />
                    </Col>
                  </Row>

                  {/* <Row>
                    <Col className="mb-3">
                      <Typography variant="small">
                        {t("No account yet")}?{" "}
                        <Link to="/auth/register">{t("Create here")}</Link>
                      </Typography>
                    </Col>
                    <Col className="mb-3 text-right">
                      <Typography variant="small">
                        {t("Forgot password?")}{" "}
                        <Link to="/auth/password/forgot">
                          {t("Reset here")}
                        </Link>
                      </Typography>
                    </Col>
                  </Row> */}

                  <div className="text-center text-muted mb-4">
                    <Button disabled={isSubmitting} type="submit">
                      <Typography bold className="text-white">
                        {isSubmitting ? (
                          <>
                            <Spinner size={"sm"} /> {t("Loading")}
                          </>
                        ) : (
                          t("Login")
                        )}
                      </Typography>
                    </Button>
                  </div>

                  {Boolean(errors["general"]) && (
                    <div className="text-center text-muted mb-2">
                      <Typography className="text-danger">
                        {errors["general"]}
                      </Typography>
                    </div>
                  )}

                  {/* {errors?.errorCode &&
                  errors?.errorCode === "USER_NOT_ACTIVATED" ? (
                    <div className="text-center mb-4">
                      <Button
                        disabled={isSubmitting}
                        variant="secondary"
                        onClick={() =>
                          handleResendActivationEmail(values?.email)
                        }
                      >
                        {t("Resend activation email")}
                      </Button>
                    </div>
                  ) : null} */}

                  <div className="mb-2"></div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const defaultValues = {
  email: "",
  password: "",
};

const LoginFormik = withFormik({
  validateOnMount: true,
  mapPropsToValues: (props) => {
    const { preSetValues } = props;
    if (preSetValues) {
      return preSetValues;
    } else {
      return defaultValues;
    }
  },
  validationSchema: (props) => {
    const { t } = props;
    const required = t("The field is required");

    return Yup.object().shape({
      email: Yup.string().email(t("Check email format")).required(required),
      password: Yup.string().required(required),
    });
  },
  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    const { t } = props;
    httpLogin(values?.email, values?.password).then(
      (res) => {
        setSubmitting(false);
        if (res?.data && res?.status === 200) {
          let { token } = res.data;
          console.log("res.data", res.data);
          localStorage.setItem("accessToken", token);
          // localStorage.setItem("refreshToken", refresh);
          props.handleResolve();
        } else {
          if (res?.data?.errorCode === "USER_NOT_ACTIVATED") {
            setErrors({
              general: t("Account not activated"),
              errorCode: "USER_NOT_ACTIVATED",
            });
          } else {
            setErrors({
              general: t("Wrong username or password"),
            });
          }
        }
      },
      (error) => {
        setSubmitting(false);
        const details = error?.data?.detail
          ? error?.data?.detail
          : t("Wrong username or password");

        if (error?.status === 401) {
          setErrors({
            general: details,
          });
        } else if (error?.status === 400) {
          setErrors({
            general: error?.data?.message,
          });
        } else {
          setErrors({
            general: `${t(
              "Error. Please contact support if the error continues"
            )} ${JSON.stringify(error)}`,
          });
        }
      }
    );
  },
  displayName: "BasicForm",
})(Login);

const LoginView = ({ match, history, location }) => {
  // API requests here

  const { t } = useTranslation();
  const { getMyUser } = useAuthContext();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  // const login = useStoreActions((actions) => actions.auth.login);

  // const [channelId, setChannelId] = useState(-1);

  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }, []);

  // useEffect(() => {
  //   const keys = queryString.parse(location?.search)
  //   if (keys?.channelId)
  //   {
  //     setChannelId(parseInt(keys?.channelId))
  //   }
  // }, [location]);

  const handleModalConfirm = () => {
    setInfoAlert(null);
    getMyUser();
    // if (channelId && channelId > -1) {
    //   history.push(`/dashboard/channel_id/${channelId}`);
    // }
    // else {
    history.push("/dashboard/account");
    // }
  };

  const handleResolve = () => {
    notify({ title: t("Käyttäjä"), message: t("Login successful") });
    handleModalConfirm();
    /*
    setInfoAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Lähetetty"
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
        Kutsu lähetetty onnistuneesti
      </ReactBSAlert>
    )
    */
  };

  return (
    <>
      <LoginFormik
        history={history}
        t={t}
        handleResolve={handleResolve}
        notify={notify}
      />
      {infoAlert}
    </>
  );
};

export default LoginView;
