import React, { createContext, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import appConfig from "services/config";
// import { setToken } from "services/token";
import { httpGetMyUser } from "services/users";
import i18n from "services/i18n";

export const AuthContext = createContext({});

const AuthProviderComponent = ({ history, location, children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [myUser, setMyUser] = useState();

  // const login = (accessToken, refreshToken) => {
  //   localStorage.setItem("accessToken", accessToken);
  //   localStorage.setItem("refreshToken", refreshToken);
  //   getMyUser();
  // };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    // window.location.reload();
    // window.location.replace("/auth/login");
    // window.location.href = "https://www.sarkain.com";
    window.location.href = "/";
  };

  const getCurrentLanguage = () => {
    return localStorage.getItem("language", "fi");
  };

  const setLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  const getMyUser = (params) => {
    return new Promise((resolve, reject) => {
      if (appConfig?.TEST_NO_BACKEND === true) {
        setIsAuthenticated(true);
        setLoading(false);
        return resolve({ id: 1 });
      }

      if (location?.pathname.startsWith("/auth/invitation")) {
        setLoading(false);
        reject();
        // return;
      }

      if (localStorage.getItem("accessToken") === null) {
        setIsAuthenticated(false);
        setLoading(false);
        reject();
      } else {
        setLoading(true);
        httpGetMyUser()
          .then(
            (response) => {
              if (response && response.status === 200) {
                setMyUser(response?.data);
                setIsAuthenticated(true);
                resolve(response?.data);
              } else {
                reject();
              }
            },
            (error) => {
              setIsAuthenticated(false);
              reject();
            }
          )
          .finally((res) => {
            setLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    getMyUser();
  }, []);

  if (loading) {
    return <>Ladataan...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        getMyUser,
        isAuthenticated,
        loading,
        logout,
        myUser,
        getCurrentLanguage,
        setLanguage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const AuthProvider = withRouter(AuthProviderComponent);

AuthContext.defaultProps = {};
AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => useContext(AuthContext);

/*
# USAGE

import { useAuthContext } from "contexts/AuthContext"
const MyComponent = (props) => {
  const { myUser } = useAuthContext()
}
*/
