import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const SortableTable = ({ columns, data, onSort }) => {
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    // Tarkista, ettei `onSort` aiheuta tarpeetonta uusintalaskentaa
    if (onSort) {
      onSort({ key, direction });
    }
  };

  const sortedData = useMemo(() => {
    // Tarkista ettei data-tilan päivitykset aiheuta ongelmia
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        let aValue = get(a, sortConfig.key);
        let bValue = get(b, sortConfig.key);

        if (!aValue && !bValue) return 0;
        if (!aValue) return sortConfig.direction === "ascending" ? -1 : 1;
        if (!bValue) return sortConfig.direction === "ascending" ? 1 : -1;

        aValue = aValue.toString().trim();
        bValue = bValue.toString().trim();

        return (
          aValue.localeCompare(bValue) *
          (sortConfig.direction === "ascending" ? 1 : -1)
        );
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  return (
    <div className="fixed-table-container ">
      <table className="fixed-table">
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col.key}
                onClick={() => col.sortable !== false && handleSort(col.key)}
                style={{
                  cursor: col.sortable !== false ? "pointer" : "default",
                  width: col.width || "auto",
                }}
              >
                {col.label}{" "}
                {col.sortable !== false && (
                  <i
                    className={`fas fa-sort-up table-sort-icon ${
                      sortConfig.key === col.key &&
                      sortConfig.direction === "ascending"
                        ? "rotate"
                        : ""
                    }`}
                  ></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td key={col.key}>
                  {col.format ? col.format(item) : get(item, col.key, "")}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.func,
      sortable: PropTypes.bool,
      width: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort: PropTypes.func,
};

export default SortableTable;
