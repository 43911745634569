import http from "./api";

const url = "/delivered_orders";

export const httpGetDeliveredOrders = (params) => {
  return http({
    url: url,
    method: "GET",
    params,
  });
};
