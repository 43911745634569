import React, { createContext, useContext, useRef } from "react";
import PropTypes from "prop-types";
// https://www.npmjs.com/package/react-notification-alert
import NotificationAlert from "react-notification-alert";

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const notificationRef = useRef(null);

  const notify = ({
    place = "bl",
    title = "",
    message = "",
    type = "default",
  }) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "fas fa-bell",
      autoDismiss: 7,
    };
    notificationRef.current.notificationAlert(options);
  };

  return (
    <NotificationContext.Provider
      value={{
        notify,
      }}
    >
      {children}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationRef} />
      </div>
    </NotificationContext.Provider>
  );
};

NotificationContext.defaultProps = {};
NotificationContext.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]),
};

export const useNotification = () => useContext(NotificationContext);
