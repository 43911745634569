import React, { useState } from "react";

import { httpUpdateUser } from "services/users";

import { useTranslation } from "react-i18next";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";

import { Typography } from "stories/components";
import { Input } from "stories/forms";

const ChangePassword = ({ user, show, toggle }) => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChangePassword = async () => {
    setError("");
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setError(t("passwordMismatch"));
      return;
    }

    if (newPassword.length < 8) {
      setError(t("passwordMinimumLength"));
      return;
    }

    const data = {
      password: newPassword,
    };

    try {
      const response = await httpUpdateUser(user?.id, data);

      if (response.status === 200) {
        setSuccess(t("passwordChangedSuccessfully"));
      } else {
        const errorData = await response.json();
        setError(errorData.message || t("passwordChangeError"));
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("changeUserPassword")} {user?.email}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Typography>{t("newPassword", "Uusi salasana")}</Typography>
            <Input
              type="password"
              value={newPassword}
              placeholder={t("newPassword", "Uusi salasana")}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography>
              {t("Confirm new password", "Vahvista uusi salasana")}
            </Typography>
            <Input
              type="password"
              value={confirmPassword}
              placeholder={t("Confirm new password", "Vahvista uusi salasana")}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {error && (
              <Alert variant="danger" className="infoMessage">
                {error}
              </Alert>
            )}
            {success && (
              <Alert variant="success" className="infoMessage">
                {success}
              </Alert>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="success" onClick={handleChangePassword}>
          <Typography bold className="text-white">
            {t("Change password", "Vaihda salasana")}
          </Typography>
        </Button>

        <Button variant="danger" onClick={toggle}>
          <Typography bold className="text-white">
            {t("Cancel", "Peruuta")}
          </Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePassword;
