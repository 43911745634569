console.log("ENV.REACT_APP_ENV", process?.env?.REACT_APP_ENV);

let apiUrl = "http://localhost:8000/api";

if (process?.env?.REACT_APP_ENV === "prod") {
  apiUrl = "http://localhost:8000/api";
} else if (process?.env?.REACT_APP_ENV === "lenovo") {
  // apiUrl = 'http://51.124.70.234/api';
  apiUrl = "http://localhost:8000/api";
} else if (process?.env?.REACT_APP_ENV === "moltres") {
  apiUrl = "http://localhost:8000/api";
} else if (process?.env?.REACT_APP_ENV === "dev") {
  apiUrl = "https://secure.varastosovellus.fi/api";
}

const config = {
  apiUrl: apiUrl,
  DEFAULT_LANGUAGE: "fi",
  TEST_NO_BACKEND: false,
  /*
  tagManagerArgs: {
    gtmId: 'GTM-XXXXXXX'
  }
  */
};

console.log("config", config);

export default config;
