import React from "react";
import { Row as BootstrapRow } from "reactstrap";
import "./Row.css";

export const Row = ({ children, ...rest }) => {
  return <BootstrapRow {...rest}>{children}</BootstrapRow>;
};

Row.propTypes = {};

Row.defaultProps = {};
