import React, { useCallback, useState, useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import queryString from "query-string";

// core components
import { Checkbox, Input } from "stories/forms";
import { AlertModal, Button, Link, Typography } from "stories/components";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "stories/layout";
// import { useNotification } from "stories/components/Notification";
import { cleanEmail } from "services/helpers";

import { httpRegisterUser } from "services/users";

import { useAppContext } from "contexts/AppContext";

const requireAccountActivation = true;

const Register = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    validateForm,
    setFieldValue,
  } = props;

  const { t } = props;

  const { enums } = useAppContext();

  const getFormikInputProps = useCallback(
    (name) => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange,
    }),
    [values, errors, touched, handleBlur, handleChange]
  );

  // const handleCancel = () => {
  //   history.push("/");
  // }

  const getGenders = useCallback(() => {
    // const bank_expenses = enums?.suppliers?.supplier?.bank_expenses;
    const genders = [
      { key: 1, value: "Male" },
      { key: 2, value: "Female" },
      { key: 3, value: "Other" },
    ];
    return (
      genders &&
      genders.map((gender) => {
        return <option value={gender.key}>{gender.value}</option>;
      })
    );
  }, [enums]);

  return (
    <>
      <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <Col lg="7" md="9" className="">
            <Card variant="secondary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">
                    {t("Register")}
                  </Typography>
                  {/* 
                    <Typography variant="p">{t("Aloita palvelun käyttö luomalla tunnukset")}</Typography>
                    */}
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("Email address (username)")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email_confirm")}
                      label={t("Confirm email address")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password")}
                      label={t("Password")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password2")}
                      label={t("Confirm password")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("first_name")}
                      label={t("First name")}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("last_name")}
                      label={t("Last name")}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("gender")}
                      label={t("Gender")}
                      type="select"
                    >
                      {getGenders()}
                    </Input>
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("date_of_birth")}
                      label={t("Date of birth")}
                      type="date"
                    />
                  </Col>
                </Row>

                {/*
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("country_of_residence")}
                      label={t("Country of residence")}
                    />
                  </Col>
                </Row>
                */}

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("country_of_origin")}
                      label={t("Country")}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("www_site")}
                      label={t("Home page")}
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("registering_code")}
                      label={t("Do you have a code?")}
                    />
                  </Col>
                </Row> */}

                <div className="row justify-content-start">
                  <div className="ml-3 mb-3">
                    <Checkbox
                      id="register_agree_terms"
                      name="agree_terms"
                      checked={values?.agree_terms}
                      onChange={(e) => {
                        setFieldValue("agree_terms", e.target.checked);
                      }}
                      value="agree_terms"
                      required
                      errorText={
                        touched["agree_terms"] ? errors["agree_terms"] : ""
                      }
                      label={
                        <>
                          <Link isExternal={true} href="*">
                            {t("I agree to the terms and conditions")}
                          </Link>
                        </>
                      }
                    />
                  </div>
                </div>

                <div className="row justify-content-start">
                  <div className="ml-3 mb-3">
                    <Checkbox
                      id="register_not_robot"
                      name="not_robot"
                      checked={values?.not_robot}
                      onChange={(e) => {
                        setFieldValue("not_robot", e.target.checked);
                      }}
                      value="not_robot"
                      required
                      errorText={
                        touched["not_robot"] ? errors["not_robot"] : ""
                      }
                      label={t("I am not a robot")}
                    />
                  </div>
                </div>

                <div className="text-center text-muted mb-4">
                  <Button
                    disabled={!isValid || isSubmitting}
                    onClick={() => validateForm().then(() => handleSubmit())}
                  >
                    {t("Register")}
                  </Button>
                  {Boolean(errors["general"]) && (
                    <Typography className="text-danger">
                      {errors["general"]}
                    </Typography>
                  )}
                </div>

                <Row>
                  <Col className="mb-3 text-center">
                    <Typography variant="small">
                      {t("Already have an account?")}{" "}
                      <Link to="/auth">{t("Login?")}</Link>
                    </Typography>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password2: "",
  agree_terms: false,
  not_robot: false,
  gender: 1,
  country_of_residence: "",
  country_of_origin: "",
  www_site: "",
};

const RegisterFormik = withFormik({
  validateOnMount: true,
  mapPropsToValues: (props) => {
    const { preSetValues } = props;
    if (preSetValues) {
      return preSetValues;
    } else {
      return defaultValues;
    }
  },
  validationSchema: (props) => {
    const { t } = props;
    const required = t("Field is required");

    return Yup.object().shape({
      first_name: Yup.string().required(required),
      last_name: Yup.string().required(required),
      email: Yup.string().email(t("Check email format")).required(required),
      password: Yup.string().required(required),
      password2: Yup.string().required(required),
      agree_terms: Yup.bool().test(
        "consent",
        t("Field is required"),
        (value) => value === true
      ),
      not_robot: Yup.bool().test(
        "consent",
        t("Field is required"),
        (value) => value === true
      ),
    });
  },
  validate: (values, props) => {
    const { t } = props;
    const errors = {};

    /*
       if (!values.email) {
         errors.email = 'Required';
       } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
         errors.email = 'Invalid email address';
       }
       */
    if (
      values?.email &&
      values?.email_confirm &&
      values?.email !== values?.email_confirm
    ) {
      errors.email = t("Email does not match");
    }

    if (
      values?.password &&
      values?.password2 &&
      values?.password !== values?.password2
    ) {
      errors.password = t("Passwords does not match");
    }

    return errors;
  },
  handleSubmit: (values, { setSubmitting, setErrors, props }) => {
    const { t } = props;

    /*
      if (values?.password != values?.password2) {
        setErrors({"password": t("Passwords does not match")})
        setSubmitting(false)
        return;
      }
      */

    const email = cleanEmail(values?.email);
    const data = {
      ...values,
      email: email,
      username: email,
      password: values?.password,
      send_activation_email: 1,
      date_of_birth: values?.date_of_birth
        ? moment(values?.date_of_birth).format()
        : null,
      // send_invitation_email: 1,
    };
    httpRegisterUser(data).then(
      (res) => {
        setSubmitting(false);
        if (res?.status === 201) {
          props.handleResolve();
        } else {
          setErrors({
            ...res?.data,
            general: t(
              "Rekisteröinti epäonnistui. Tarkista lomakkeen virheet."
            ),
          });
        }
      },
      (error) => {
        setSubmitting(false);
        setErrors({
          general: `${t(
            "Virhe. Ota tarvittaessa yhteys ylläpitoon."
          )} ${JSON.stringify(error)}`,
        });
      }
    );
  },
  displayName: "BasicForm",
})(Register);

const RegisterView = ({ match, history, location }) => {
  // API requests here

  const { t } = useTranslation();
  // const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  const [channelId, setChannelId] = useState(-1);

  useEffect(() => {
    localStorage.removeItem("accessToken");
  }, []);

  useEffect(() => {
    const keys = queryString.parse(location?.search);
    if (keys?.channelId) {
      setChannelId(parseInt(keys?.channelId));
    }
  }, [location]);

  const handleModalConfirm = () => {
    setInfoAlert(null);
    history.push(`/auth/login?channelId=${channelId}`);
  };

  const handleResolve = () => {
    if (requireAccountActivation) {
      setInfoAlert(
        <AlertModal
          style={{ display: "block", marginTop: "-100px" }}
          title={t("Account activation")}
          onConfirm={() => handleModalConfirm()}
          onCancel={() => handleModalConfirm()}
          btnSize=""
        >
          {t(
            "Please activate your account. We have sent activation link to your email."
          )}
        </AlertModal>
      );
    } else {
      handleModalConfirm();
    }
  };

  return (
    <>
      <RegisterFormik t={t} history={history} handleResolve={handleResolve} />
      {infoAlert}
    </>
  );
};

export default RegisterView;
