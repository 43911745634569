import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      orderAdditionFailed:
        "Failed to add order. Please check the entered information and try again.",
      orderDeliveredSuccessfully: "Order delivered successfully!",
      orderDeliveryFailed: "Order delivery failed. Please try again.",
      cancellationSuccessful: "Cancellation successful",
      cancellationFailed: "Cancellation failed. Please try again.",
      orders: "Orders",
      deliveredOrders: "Delivered orders",
      order: "Order",
      username: "Username",
      name: "Name",
      date: "Date",
      action: "Action",
      userDeliverOrders: "User (Can deliver orders)",
      userCancelOrders: "User (Can cancel orders)",
      admin: "Admin",
      addNewUser: "Add new user",
      changePassword: "Change password",
      deleteUser: "Delete user",
      role: "Role",
      passwordMismatch: "The new password and confirmation do not match.",
      passwordMinimumLength:
        "The new password must be at least 8 characters long.",
      passwordChangedSuccessfully: "Password changed successfully.",
      passwordChangeError: "An error occurred while changing the password.",
      newPassword: "New password",
      changeUserPassword: "Change password for the user",
      "Confirm new password": "Confirm new password",
      "Change password": "Change password",
      confirmUserDeletion: "Are you sure you want to delete the user",
      userDeletionError: "deleting the user. Please try again later!",
      userDeletedSuccessfully: "User deleted successfully!",
      invalidEmailAddress: "Invalid email address",
      userCreatedSuccessfully: "User created successfully!",
      userCreationError: "Error creating the user. Please try again later!",
      addUser: "Add new user",
      password: "Password",
      selectRole: "Select role",
      createUser: "Create user",
      edit: "Edit",
      delete: "Delete",
      Save: "Save",
      productUpdated: "Product updated successfully!",
      errorProductUpdate: "product update failed!",
      productDeleted: "Product deleted successfully!",
      ErrorDeletingProduct: "product deletion failed!",
      confirmDeletion: "Confirm deletion",
      confirmDeleteItem: "Are you sure you want to delete this item?",
      print: "Print",
      previous: "previous",
      next: "next",
      noRush: "No rush",
      normal: "Normal",
      urgent: "Urgent",
      "Log in": "Log in",
      Logout: "Logout",
      "My account": "My account",
      Register: "Register",
      Login: "Login",
      Email: "Email",
      Password: "Password",
      "No account yet": "No account yet",
      "Create here": "Create here",
      "Forgot password?": "Forgot password?",
      "Reset here": "Reset here",
      "Not arrive": "Not arrive",
      "Wrong username or password": "Wrong username or password",
      Incomplete: "Incomplete",
      Arrived: "Arrived",
      Open: "Open",
      "In progress": "In progress",
      Complete: "Complete",
      Damaged: "Damaged",
      "Not paid": "Not paid",
      Company: "Company",
      Supplier: "Supplier",
      "Partially paid": "Partially paid",
      "Fully paid": "Fully paid",
      Draft: "Draft",
      Confirmed: "Confirmed",
      Paid: "Paid",
      "On delivery": "On delivery",
      Archived: "Archived",
      New: "New",
      "Customer order": "Customer order",
      Complaint: "Complaint",
      Basic: "Basic",
      "new password does not match with confirm password":
        "New password does not match with confirm password",
      "Product number not found": "Product number not found.",
      Error: "Error",
      "There is already an undelivered order for this product. You must deliver it first before creating a new order.":
        "There is already an undelivered order for this product. You must deliver it first before creating a new order.",
      "Created order": "Created order {{productNumber}}",
      "Delivered order":
        "Delivered order {{productNumber}} to {{storageLocation}}",
      "Deleted order": "Deleted order {{productNumber}}",
      "Created product":
        "Created product {{productNumber}} in storage location {{storageLocation}}",
      "Created storage location":
        "Created storage location {{storageLocation}}",
      "Deleted product": "Deleted product {{productNumber}}",
      "Deleted storage location":
        "Deleted storage location {{storageLocation}}",
      "Logged in": "Logged in",
      ProductAdded:
        "Product {{newProduct}} successfully added to storage location {{storageLocation}}!",
      serialNumber: "Serial number",
      storageLocation: "Storage location",
      "Select from the list": "Select from the list",
      "Add product to warehouse": "add product to storage",
      "Add product": "Add product",
      Warehouse: "Storage",
      Production: "Production",
      Log: "Log",
      Admin: "Admin",
      added: "Added",
      "Added by": "Added by",
      WarehouseLocationNotFound: "Storage location not found or data is empty.",
      ErrorFetchingStorage:
        "While fetching storage locations. Please try again!",
      "New warehouse location": "New storage location",
      Search: "Search",
      Loading: "Loading",
      "Get all": "Get all",
      Print: "Print",
      "Show empty": "Show empty",
      newStorageLocationCreated: "New storage location created successfully.",
      storageLocationCreationFailed: "Creating the storage location failed.",
      createNewStorageLocation: "create a new storage location",
      newWarehouseLocation: "New storage location",
      cancel: "Cancel",
      Orderer: "Orderer",
      Ordered: "Ordered",
      Delivery: "Delivery",
      productionLine: "Production line",
      Actions: "Actions",
      Deliver: "Deliver",
      "fetching delivered orders": "when retrieving delivered orders",
      fetchingOrdersTryAgainLater: "Fetching orders. Try again later.",
      "Order added successfully": "Order added successfully",
      Orders: "Orders",
      editItem: "Edit product and storage location",
      roleChange: "Shall the role be changed?",
      confirmRoleChange: "Are you sure you want to change the user's role?",
      change: "Change",
    },
  },
  fi: {
    translation: {
      "Log in": "Kirjaudu sisään",
      Logout: "Kirjaudu ulos",
      "My account": "Oma tili",
      Register: "Rekisteröidy",
      Login: "Kirjaudu",
      Email: "Sähköposti",
      Password: "Salasana",
      "No account yet": "Ei tunnusta vielä",
      "Create here": "Tee täällä",
      "Forgot password?": "Unohditko salasanan",
      "Reset here": "Palauta tästä",
      "Not arrive": "Ei saapunut",
      "Wrong username or password": "Väärä käyttäjänimi tai salasana",
      Incomplete: "Puutteellinen",
      Arrived: "Saapunut",
      Open: "Avoin",
      "In progress": "Käsittelyssä",
      Complete: "Valmis",
      Damaged: "Rikki",
      "Not paid": "Ei maksettu",
      Company: "Yritys",
      Supplier: "Tavarantoimittaja",
      "Partially paid": "Osittain maksettu",
      "Fully paid": "Kokonaan maksettu",
      Draft: "Luonnos",
      Confirmed: "Vahvistettu",
      Paid: "Maksettu",
      "On delivery": "Matkalla",
      Archived: "Arkistoitu",
      New: "Uusi",
      "Customer order": "Asiakastilaus",
      Complaint: "Reklamaatio",
      Basic: "Perus",
      "new password does not match with confirm password":
        "Uusi salasana ei täsmää vahvistussalasanan kanssa",
      "Product number not found": "Tuotenumeroa ei löytynyt.",
      Error: "Virhe",
      "There is already an undelivered order for this product. You must deliver it first before creating a new order.":
        "Tälle tuotteelle on jo olemassa toimitettavaksi jäänyt tilaus. Sinun täytyy toimittaa se ensin ennen uuden tilauksen luomista.",
      "Created order": "Loi tilauksen {{productNumber}}",
      "Delivered order":
        "Toimitti tilauksen {{productNumber}} {{storageLocation}}",
      "Deleted order": "Poisti tilauksen {{productNumber}}",
      "Created product":
        "Loi tuotteen {{productNumber}} varastopaikkaan {{storageLocation}}",
      "Created storage location": "Luotu varastopaikka {{storageLocation}}",
      "Deleted product": "Poisti tuotteen {{productNumber}}",
      "Deleted storage location": "Poisti varastopaikan {{storageLocation}}",
      "Logged in": "Kirjautunut sisään",
      ProductAdded:
        "Tuote {{newProduct}} lisätty varastopaikkaan {{storageLocation}} onnistuneesti!",
      serialNumber: "Valmistenumero",
      storageLocation: "Varastopaikka",
      "Select from the list": "Valitse listasta",
      "Add product to warehouse": "Lisää tuote varastoon",
      "Add product": "Lisää tuote",
      Warehouse: "Varasto",
      Production: "Tuotanto",
      Log: "Loki",
      Admin: "Admin",
      added: "Lisätty",
      "Added by": "Lisääjä",
      WarehouseLocationNotFound: "Varastopaikkaa ei löydy tai data on tyhjä",
      ErrorFetchingStorage: "Haettaessa varastopaikkoja. Kokeile uudelleen!",
      "New warehouse location": "Uusi varastopaikka",
      Search: "Suorita haku",
      Loading: "Lataa",
      "Get all": "Hae kaikki",
      Print: "Tulosta",
      "Show empty": "Näytä tyhjät",
      newStorageLocationCreated: "Uusi varastopaikka luotu onnistuneesti.",
      storageLocationCreationFailed: "Varastopaikan luominen epäonnistui.",
      createNewStorageLocation: "Luo uusi varastopaikka",
      newWarehouseLocation: "Uusi varastopaikka",
      cancel: "Peruuta",
      Orderer: "Tilaaja",
      Ordered: "Tilattu",
      Delivery: "Toimitus",
      productionLine: "Tuotantolinja",
      Actions: "Toiminnot",
      Deliver: "Toimita",
      "fetching delivered orders": "toimitettuja tilauksia haettaessa",
      fetchingOrdersTryAgainLater:
        "Tilauksia haettaessa. Yritä uudelleen myöhemmin.",
      "Order added successfully": "Tilauksen lisääminen onnistui",
      orderAdditionFailed:
        "Tilauksen lisääminen epäonnistui. Tarkista syötetyt tiedot ja yritä uudelleen.",
      orderDeliveredSuccessfully: "Tilaus toimitettu onnistuneesti!",
      orderDeliveryFailed:
        "Tilauksen toimitus epäonnistui. Yritä myöhemmin uudelleen!",
      cancellationSuccessful: "Peruuttaminen onnistui",
      cancellationFailed: "Peruuttaminen epäonnistui. Yritä uudelleen",
      Orders: "Tilaukset",
      deliveredOrders: "Toimitetut tilaukset",
      order: "Tilaa",
      username: "Käyttäjätunnus",
      name: "Nimi",
      date: "Päivämäärä",
      action: "Toiminto",
      userDeliverOrders: "Käyttäjä (Voi hyväksyä tilauksia)",
      userCancelOrders: "Käyttäjä (Voi poistaa tilauksia)",
      admin: "Admin",
      addNewUser: "Lisää uusi käyttäjä",
      changePassword: "Vaihda salasana",
      deleteUser: "Poista käyttäjä",
      role: "Rooli",
      passwordMismatch: "Uusi salasana ja vahvistus eivät täsmää.",
      passwordMinimumLength:
        "Uuden salasanan on oltava vähintään 8 merkkiä pitkä.",
      passwordChangedSuccessfully: "Salasana vaihdettu onnistuneesti.",
      passwordChangeError: "Salasanan vaihdossa tapahtui virhe.",
      newPassword: "Uusi salasana",
      changeUserPassword: "Vaihda salasana käyttäjälle",
      "Confirm new password": "Vahvista uusi salasana",
      "Change password": "Vaihda salasana",
      confirmUserDeletion: "Haluatko varmasti poistaa käyttäjän",
      userDeletionError: "käyttäjää poistettaessa. Yritä myöhemmin uudelleen!",
      userDeletedSuccessfully: "Käyttäjä poistettu onnistuneesti!",
      invalidEmailAddress: "Virheellinen sähköpostiosoite",
      userCreatedSuccessfully: "Käyttäjä luotu onnistuneesti!",
      userCreationError:
        "Virhe käyttäjän luonnissa. Yritä myöhemmin uudelleen!",
      addUser: "Lisää käyttäjä",
      password: "Salasana",
      selectRole: "Valitse rooli",
      createUser: "Luo käyttäjä",
      edit: "Muokkaa",
      delete: "Poista",
      editItem: "Muokkaa tuotetta ja varastopaikkaa",
      Save: "Tallenna",
      productUpdated: "Tuote päivitetty onnistuneesti!",
      errorProductUpdate: "tuotteen päivitys epäonnistui!",
      productDeleted: "Tuotteen poisto onnistui!",
      ErrorDeletingProduct: "tuotteen poisto epäonnistui!",
      confirmDeletion: "Vahvista poisto",
      confirmDeleteItem: "Oletko varma, että haluat poistaa tämän kohteen?",
      roleChange: "Muutetaanko rooli?",
      confirmRoleChange: "Haluatko varmasti muuttaa käyttäjän roolin?",
      change: "Vaihda",
      print: "Tulosta",
      previous: "Edellinen",
      next: "Seuraava",
      noRush: "Ei kiirettä",
      normal: "Normaali",
      urgent: "Kiireellinen",
    },
  },
  es: {
    translation: {
      "Log in": "Logi sisse",
      Logout: "Logi välja",
      "My account": "Oma tili",
      Register: "Rekisteröidy",
      Login: "Logi sisse",
      Email: "Kasutajanimi",
      Password: "Parool",
      Incomplete: "Puutteellinen",
      Arrived: "Saabunud",
      Open: "Avoin",
      "In progress": "Käsittelyssä",
      Complete: "Valmis",
      Damaged: "Rikki",
      "Not paid": "Ei maksettu",
      Company: "Yritys",
      Supplier: "Tavarantoimittaja",
      "Partially paid": "Osittain maksettu",
      "Fully paid": "Kokonaan maksettu",
      Draft: "Luonnos",
      Confirmed: "Vahvistettu",
      Paid: "Maksettu",
      "On delivery": "Matkalla",
      Archived: "Arkistoitu",
      New: "Uusi",
      "Customer order": "Asiakastilaus",
      Complaint: "Reklamaatio",
      Basic: "Põhi",
      "Wrong username or password": "Kontrolli kasutajanime ja parooli.",
      "new password does not match with confirm password":
        "Uus parool ei ühti kinnitamise parooliga",
      "Product number not found": "Tootenumbrit ei leitud",
      Error: "Viga!",
      "There is already an undelivered order for this product. You must deliver it first before creating a new order.":
        "Sellele tootele on juba olemas kohaletoimetamata tellimus. Pead selle esmalt kohaletoimetama enne uue tellimuse loomist.",
      "Created order": "Loonud tellimuse {{productNumber}}",
      "Delivered order":
        "Kohaletoimetatud tellimus {{productNumber}} {{storageLocation}}",
      "Deleted order": "Eemaldatud tellimus {{productNumber}}",
      "Created product":
        "Loonud toote {{productNumber}} laoasukohta {{storageLocation}}",
      "Created storage location": "Loodud laoasukoht {{storageLocation}}",
      "Deleted product": "Eemaldatud toode {{productNumber}}",
      "Deleted storage location": "Eemaldatud laoasukoht {{storageLocation}}",
      "Logged in": "Sisselogitud",
      ProductAdded:
        "Toode {{newProduct}} lisatud laoasukohta {{storageLocation}} edukalt!",
      serialNumber: "Seerianumber",
      storageLocation: "Pesa",
      "Select from the list": "Vali nimekirjast",
      "Add product to warehouse": "Lisa rull lattu",
      "Add product": "Lisa rull",
      Warehouse: "Ladu",
      Production: "Tootmine",
      Log: "Logi",
      Admin: "Admin",
      added: "Kuupäev",
      "Added by": "Kasutaja",
      WarehouseLocationNotFound: "Pesa ei ole loodud",
      ErrorFetchingStorage: "Laoasukohtade otsimisel. Proovi uuesti!",
      "New warehouse location": "Uus pesa",
      Search: "Otsi",
      Loading: "Laadi alla",
      "Get all": "Otsi kõiki",
      Print: "Prindi",
      "Show empty": "Näita tühjasid",
      newStorageLocationCreated: "Uus laoasukoht on edukalt loodud",
      storageLocationCreationFailed: "Laoasukoha loomine ebaõnnestus",
      createNewStorageLocation: "Pesa nimi",
      newWarehouseLocation: "Uus pesa",
      cancel: "Tühista",
      Orderer: "Tellija",
      Ordered: "Tellitud",
      Delivery: "Tarni",
      productionLine: "Tootmisliin",
      Actions: "Toiming",
      Deliver: "Tarni",
      "fetching delivered orders": "Kohaletoimetatud tellimuste otsimisel",
      fetchingOrdersTryAgainLater:
        "Tellimuste otsimisel. Proovi hiljem uuesti.",
      "Order added successfully": "Tellimuse lisamine õnnestus",
      orderAdditionFailed:
        "Tellimuse lisamine ebaõnnestus. Kontrolli sisestatud andmeid ja proovi uuesti.",
      orderDeliveredSuccessfully: "Rull tarniti ja eemaldati riiulist.",
      orderDeliveryFailed: "Rulli tarnimisel ilmnes viga.",
      cancellationSuccessful: "Tellimus kustutati edukalt!",
      cancellationFailed: "Tellimuse kustutamisel ilmnes viga.",
      Orders: "Tellimused",
      deliveredOrders: "Tarnitud tellimused",
      order: "Telli",
      username: "Kasutaja",
      name: "Nimi",
      date: "Kuupäev",
      action: "Toiming",
      userDeliverOrders: "Kasutaja (saab tellimusi edastada)",
      userCancelOrders: "Kasutaja (saab tellimusi tühistada)",
      admin: "Admin",
      addNewUser: "Lisage uus kasutaja",
      changePassword: "Muuda parool",
      deleteUser: "Kustuta kasutaja",
      role: "Roll",
      passwordMismatch: "Uus parool ja kinnitamine ei ühti",
      passwordMinimumLength: "Parool peab olema vähemalt 8 tähemärki pikk.",
      passwordChangedSuccessfully: "Parool on edukalt vahetatud",
      passwordChangeError: "Parooli muutmisel tekkis viga",
      newPassword: "Sisestage parool",
      changeUserPassword: "Muuda salasõna",
      "Confirm new password": "Kinnita uus parool",
      "Change password": "Muuda salasõna",
      confirmUserDeletion: "Kas soovite kasutaja kindlasti kustutada?",
      userDeletionError: "Kasutaja kustutamisel ilmnes viga.",
      userDeletedSuccessfully: "Kasutaja kustutamine õnnestus.",
      invalidEmailAddress: "Vale e-posti aadress",
      userCreatedSuccessfully: "Kasutaja lisamine õnnestus.",
      userCreationError: "Viga kasutaja loomisel. Proovi hiljem uuesti!",
      addUser: "Lisage kasutaja",
      password: "Parool",
      selectRole: "Vali roll",
      createUser: "Loo kasutaja",
      edit: "Redigeeri",
      delete: "Kustuta",
      editItem: "Muuda seeria-ja pesa numbrit",
      Save: "Salvesta",
      productUpdated: "Toode on edukalt uuendatud!",
      errorProductUpdate: "Toote uuendamine ebaõnnestus!",
      productDeleted: "Toote eemaldamine õnnestus!",
      ErrorDeletingProduct: "Toote eemaldamine ebaõnnestus!",
      confirmDeletion: "Kinnita eemaldamine",
      confirmDeleteItem: "Kas oled kindel, et soovid selle objekti eemaldada?",
      roleChange: "Kas rolli muudetakse?",
      confirmRoleChange: "Kas oled kindel, et soovid kasutaja rolli muuta?",
      change: "Vaheta",
      print: "Prindi",
      previous: "Eelmine",
      next: "Järgmine",
      noRush: "Ei kiirettä",
      normal: "Normaali",
      urgent: "Kiireellinen",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fi",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
