import { useState } from "react";

import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { httpCreateUser } from "services/users";
import { Button, Typography } from "stories/components";
import { useNotification } from "stories/components/Notification";
import { Input } from "stories/forms";
import { Row, Col } from "stories/layout";

const AddNewUser = ({ show, toggle, fetchUsers }) => {
  const { t } = useTranslation();
  const { notify } = useNotification();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, SetSelectedRole] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const roleList = [
    {
      id: 1,
      role: t("userDeliverOrders"),
    },
    {
      id: 2,
      role: t("userCancelOrders"),
    },
    {
      id: 10,
      role: t("Admin"),
    },
  ];

  const handleCreateUser = async () => {
    setErrors({ username: "", password: "" });
    let hasErrors = false;
    const newErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      newErrors.username = t("invalidEmailAddress");
      hasErrors = true;
    }

    // Password validation
    if (password.length < 8) {
      newErrors.password = t("passwordMinimumLength");
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(newErrors);
      return; // Stop if there are validation errors
    }

    const newUser = {
      email: username,
      password: password,
      status: selectedRole.id,
    };

    createUser(newUser);
    setLoading(true);
  };

  const createUser = async (user) => {
    try {
      await httpCreateUser(user);
      notify({
        title: "",
        message: t("userCreatedSuccessfully"),
        type: "primary",
      });
      await fetchUsers();
      toggle();
    } catch (error) {
      notify({
        title: "",
        message: t("userCreationError"),
        type: "danger",
      });
      console.error("Error creating user:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Typography variant="h2">{t("addUser")}</Typography>
      </ModalHeader>
      <ModalBody style={{ minHeight: "200px" }}>
        <Row>
          <Col>
            <Typography>{t("username")}</Typography>
            <Input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="off"
            />
            {errors.username && (
              <Alert variant="danger">{errors.username}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography>{t("password")}</Typography>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="off"
            />
            {errors.password && (
              <Alert variant="danger">{errors.password}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography>{t("role")}</Typography>
            <ReactSelect
              placeholder={t("selectRole")}
              options={roleList}
              getOptionValue={(option) => `${option["id"]}`}
              getOptionLabel={(option) => `${option["role"]}`}
              onChange={(event) => SetSelectedRole(event)}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button
          variant="success"
          onClick={handleCreateUser}
          disabled={!username || !password || !selectedRole}
          loading={loading}
        >
          <Typography bold className="text-white">
            {t("createUser")}
          </Typography>
        </Button>
        <Button variant="danger" onClick={toggle}>
          <Typography bold className="text-white">
            {t("cancel")}
          </Typography>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewUser;
