import moment from "moment";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { httpGetActivity } from "services/activity";

import Loader from "components/project/Loader";
import SortableTable from "components/project/SortableTable";
import Pagination from "components/project/Pagination";

const LogView = () => {
  const { t } = useTranslation();
  const [activityLogs, setActivityLogs] = useState();
  const [loading, setLoading] = useState(false);
  // const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  useEffect(() => {
    FetchActivityLogs();
  }, [itemsPerPage, currentPage]);

  const FetchActivityLogs = async () => {
    setLoading(true);
    try {
      const response = await httpGetActivity({
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage,
      });
      setActivityLogs(response?.data?.results);
      setTotalItems(response?.data?.count);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOffsetChange = (offset) => {
    setItemsPerPage(offset);
    setCurrentPage(1);
  };

  // useEffect(() => {

  // },[filter])

  const columns = [
    {
      key: "user.email",
      label: t("username"),
      sortable: false,
      format: (item) => item.user.email,
    },
    {
      key: "user.name",
      label: t("name"),
      sortable: false,
      format: (item) =>
        item.user.first_name ? item.user.first_name : item.user.email,
    },
    {
      key: "added",
      label: t("date"),
      sortable: false,
      format: (item) => moment(item.added).format("L HH:mm"),
    },
    {
      key: "activity",
      label: t("action"),
      sortable: false,
      format: (item) => {
        return (
          <div>
            {t(item.activity, {
              productNumber: item.product_number,
              storageLocation: item.storage_location,
            })}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="container-background">
        {/* <Row className="mb-4">
          <Col>
            <Typography bold>{t("Valmistenumero")}</Typography>
            <Input
              value={filter}
              className="w-25"
              type="text"
              placeholder="0000"
              onChange={(e) => setFilter(e.target.value)}
            />
          </Col>
        </Row> */}

        {loading ? (
          <Loader />
        ) : (
          activityLogs &&
          activityLogs.length > 0 && (
            <>
              <SortableTable columns={columns} data={activityLogs} />

              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                onOffsetChange={handleOffsetChange}
              />
            </>
          )
        )}
      </div>
    </>
  );
};
export default LogView;
