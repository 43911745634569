import { createBrowserHistory } from 'history'

export default createBrowserHistory({
  /* pass a configuration object here if needed */
})

/*
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
export history;
*/
