import { useEffect, useRef, useState } from "react";

import { httpUpdateProduct, httpGetProducts } from "services/product";

import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Input, Spinner, Alert } from "reactstrap";

import { Button } from "stories/components";
import { Typography } from "stories/components";
import { Col, Row } from "stories/layout";

import styled from "styled-components";

const StyledRow = styled(Row)`
  @media (max-width: 768px) {
    flex-direction: column; // Järjestetään elementit päällekkäin mobiilissa
  }
`;

const AddProductView = () => {
  const { t } = useTranslation();
  const focusRef = useRef();

  const [productNumber, setProductNumber] = useState("");
  const [storageLoc, setStorageLoc] = useState();
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [storageLocations, setStorageLocations] = useState([]);

  useEffect(() => {
    if (successMessage || errorMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, [3000]);
    }
  }, [successMessage, errorMessage]);

  const fetchStorageLocations = async () => {
    try {
      const response = await httpGetProducts();
      const storageLocations = response.data.filter(
        (item) => item.product_number === null && item.storage_location !== ""
      );
      setStorageLocations(storageLocations);
    } catch (error) {
      // setErrorMessage("Varastopaikkojen haku epäonnistui.");
      console.error("ERROR:", error);
    }
  };

  useEffect(() => {
    fetchStorageLocations();
    focusRef.current.focus(); //set focus to the serial number field
  }, []);

  useEffect(() => {
    if (!!productNumber === true && !!storageLoc === true) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [productNumber, storageLoc]);

  // Function to update a product's storage location
  const updateProductLocation = async (productId, newProduct) => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await httpUpdateProduct(productId, {
        product_number: newProduct,
      });
      setSuccessMessage(
        t(`ProductAdded`, {
          newProduct: newProduct,
          storageLocation: storageLoc.storage_location,
        })
      );
      setStorageLoc(null);
      setProductNumber("");
      fetchStorageLocations();
    } catch (error) {
      // setErrorMessage(t("Tuotteen lisäys epäonnistui."));
      console.error("ERROR:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProduct = (event) => {
    event.preventDefault();

    const productId = storageLoc.id;
    const newProduct = productNumber;

    updateProductLocation(productId, newProduct);
  };

  return (
    <>
      <div className="container-background item-padding">
        <StyledRow>
          <Col>
            <Typography bold>{t("serialNumber")}</Typography>
            <Input
              type="text"
              value={productNumber}
              placeholder="0000"
              onChange={(event) =>
                setProductNumber(event.target.value.toUpperCase())
              }
              innerRef={focusRef}
            />
          </Col>
          <Col>
            <Typography bold>{t("storageLocation")}</Typography>
            <Select
              value={storageLoc}
              placeholder={t("Select from the list")}
              options={storageLocations}
              getOptionValue={(option) => `${option["id"]}`}
              getOptionLabel={(option) => `${option["storage_location"]}`}
              onChange={(event) => setStorageLoc(event)}
            />
          </Col>
        </StyledRow>

        {/* Display Success or Error Message */}
        {successMessage && (
          <Alert color="success" className="infoMessage">
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="infoMessage">
            {errorMessage}
          </Alert>
        )}

        <Row className="mt-5 mb-3 d-flex justify-content-center">
          <Button
            disabled={disableButton || loading}
            onClick={handleUpdateProduct}
          >
            {loading ? (
              <Spinner size="sm" color="light" />
            ) : (
              <Typography bold className="text-uppercase">
                {t("Add product to warehouse")}
              </Typography>
            )}
          </Button>
        </Row>
      </div>
    </>
  );
};

export default AddProductView;
