import { action, thunk } from "easy-peasy";
import { httpGetOrders } from "services/orders";

const orderCountModel = {
  count: 0,

  // Action to set the count
  setOrderCount: action((state, payload) => {
    state.count = payload;
  }),

  // Action to increment the count
  incrementCount: action((state, payload = 1) => {
    state.count += payload;
  }),

  // Action to decrement the count
  decrementCount: action((state, payload = 1) => {
    state.count -= payload;
  }),

  fetchOrderCount: thunk(async (actions) => {
    try {
      const response = await httpGetOrders();
      const data = await response.data.length;
      actions.setOrderCount(data);
    } catch (error) {
      console.error("Virhe:", error);
    }
  }),
};

export default orderCountModel;
