import React, { createContext, useContext, useState } from "react";
import { useStoreRehydrated } from "easy-peasy";
import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import { useStoreState, useStoreActions } from 'easy-peasy';
import { httpGetEnums } from "services/enums";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const isRehydrated = useStoreRehydrated(); // is persist data loaded
  // const { t } = useTranslation();
  const [enums, setEnums] = useState();

  const getEnums = () => {
    httpGetEnums().then(
      (response) => {
        setEnums(response?.data);
      },
      (error) => {
        console.log("getEnums error", error);
      }
    );
  };

  return isRehydrated ? (
    <AppContext.Provider
      value={{
        enums,
        getEnums,
      }}
    >
      {children}
    </AppContext.Provider>
  ) : (
    "Loading..."
  );
};

AppContext.defaultProps = {};
AppContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppContext = () => useContext(AppContext);
