import moment from "moment";
import { useState, useEffect } from "react";

import axios from "services/api";
import { useStoreActions } from "easy-peasy";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "contexts/AuthContext";

import {
  httpGetOrders,
  httpCreateOrder,
  httpDeleteOrder,
} from "services/orders";
import { httpGetDeliveredOrders } from "services/delivered_orders";

import Select from "react-select";
import { Alert, Input, Spinner } from "reactstrap";

import { Button, Typography } from "stories/components";
import { Col, Row } from "stories/layout";
import { useNotification } from "stories/components/Notification";
import SortableTable from "components/project/SortableTable";
import Pagination from "components/project/Pagination";

// const deliveryTimes = [
//   {
//     id: 0,
//     time: "24h",
//   },
//   {
//     id: 1,
//     time: "2h",
//   },
//   {
//     id: 2,
//     time: "30min",
//   },
// ];

const ProductionView = () => {
  const { t } = useTranslation();
  const { myUser } = useAuthContext();
  const { notify } = useNotification();

  const [ordersList, setOrdersList] = useState([]);
  const [deliveredList, setDeliveredList] = useState([]);

  const [productNumber, setProductNumber] = useState("");
  const [productionLine, setProductionLine] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const incrementCount = useStoreActions(
    (actions) => actions.orderCount.incrementCount
  );
  const decrementCount = useStoreActions(
    (actions) => actions.orderCount.decrementCount
  );

  const deliveryTimes = [
    {
      id: 0,
      time: t("noRush"),
    },
    {
      id: 1,
      time: t("normal"),
    },
    {
      id: 2,
      time: t("urgent"),
    },
  ];
  const [priority, setPriority] = useState(deliveryTimes[1]); // default "2h"

  const orderColumns = [
    {
      key: "product_number",
      label: t("serialNumber"),
      sortable: true,
      format: (item) => (
        <div>
          <Typography bold>
            {item.product_number ? item.product_number : item.info_text || ""}
          </Typography>
          <Typography>{item?.product?.storage_location}</Typography>
        </div>
      ),
    },
    {
      key: "production_line",
      label: t("productionLine"),
      sortable: true,
      format: (item) => item.production_line,
    },
    {
      key: "added_by.email",
      label: t("Orderer"),
      sortable: true,
      format: (item) =>
        item.added_by.first_name
          ? item.added_by.first_name
          : item.added_by.email,
    },
    {
      key: "priority",
      label: t("Delivery"),
      sortable: true,
      format: (item) => (
        <div className="d-flex">
          <Typography
            bold
            style={{
              color:
                (item.priority === 0 && "#E6D200") ||
                (item.priority === 1 && "green") ||
                (item.priority === 2 && "red"),
            }}
          >
            <i className="fas fa-circle"></i>&nbsp;
            {deliveryTimes?.find((d) => d.id === item?.priority)?.time}
          </Typography>
        </div>
      ),
    },
    {
      key: "added",
      label: t("Ordered"),
      sortable: true,
      format: (item) => (
        <Typography>{moment(item.added).format("L HH:mm")}</Typography>
      ),
    },
    {
      key: "actions",
      label: t("Actions"),
      sortable: false,
      format: (item) => (
        <>
          {myUser?.status !== 2 && (
            <Button
              className="me-1"
              variant="success"
              onClick={() => deliverOrderHandler(item.id)}
            >
              <Typography className="text-white" bold>
                {t("Deliver")}
              </Typography>
            </Button>
          )}
          {myUser?.status !== 1 && (
            <Button
              variant="danger"
              onClick={() => cancelOrderHandler(item.id)}
              disabled={myUser.status === 1}
              className="my-1"
            >
              <Typography className="text-white" bold>
                {t("cancel")}
              </Typography>
            </Button>
          )}
        </>
      ),
    },
  ];

  const deliveredColumns = [
    { key: "product_number", label: t("serialNumber"), sortable: true },
    { key: "production_line", label: t("productionLine"), sortable: true },
    {
      key: "delivered_by.email",
      label: t("Orderer"),
      sortable: true,
      format: (user) =>
        user.added_by.first_name
          ? user.added_by.first_name
          : user.added_by.email,
    },
    {
      key: "added",
      label: t("Ordered"),
      sortable: true,
      format: (item) => (
        <>
          <span
            style={{
              color:
                (item.priority === 0 && "#E6D200") ||
                (item.priority === 1 && "green") ||
                (item.priority === 2 && "red"),
            }}
          >
            <i className="fas fa-circle"></i>&nbsp;
          </span>
          {moment(item.added).format("L HH:mm")}
        </>
      ),
    },
    {
      key: "delivered",
      label: t("Delivery"),
      sortable: true,
      format: (item) => (
        <>
          <Typography>{moment(item.delivered).format("L HH:mm")}</Typography>
          <Typography>
            {item.delivered_by.first_name
              ? item.delivered_by.first_name
              : item.delivered_by.email}
          </Typography>
        </>
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, [15000]);
  }, [errorMessage, successMessage]);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    fetchDeliveredOrders();
  }, [itemsPerPage, currentPage]);

  const fetchDeliveredOrders = async () => {
    try {
      const response = await httpGetDeliveredOrders({
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage,
      });
      setDeliveredList(response.data.results);
      setTotalItems(response?.data?.count);
      // console.log("response.data", response.data);
    } catch (error) {
      // console.log("error", error);
      notify({
        title: t("Error"),
        message: "fetching delivered orders",
        type: "danger",
      });
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await httpGetOrders();
      setOrdersList(response.data);
    } catch (error) {
      notify({
        title: t("Error"),
        message: t("fetchingOrdersTryAgainLater"),
        type: "danger",
      });
    }
  };

  const addOrderHandler = async () => {
    const newOrder = {
      product_number: productNumber,
      production_line: productionLine,
      priority: priority.id,
    };

    setIsCreatingOrder(true);
    try {
      const response = await httpCreateOrder(newOrder);
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(t("Order added successfully"));
        setProductNumber("");
        setProductionLine("");
        setPriority(deliveryTimes[1]);
        fetchOrders();
        incrementCount(1);
      }
      if (response.status === 400) {
        const errorMessage = response.data.product_number
          ? `${t("Error")}: ${t(response.data.product_number[0])}`
          : t("orderAdditionFailed");

        setErrorMessage(errorMessage);
      }
    } catch (error) {
      setErrorMessage(t("orderAdditionFailed"));
    } finally {
      setIsCreatingOrder(false);
    }
  };

  const deliverOrderHandler = async (orderId) => {
    try {
      const response = await axios.post(`/orders/${orderId}/deliver`);
      if (response.status === 200) {
        notify({
          title: "",
          message: t("orderDeliveredSuccessfully"),
          type: "primary",
        });
        fetchOrders();
        fetchDeliveredOrders();
        decrementCount(1);
      }
      if (response.status === 400) {
        notify({
          title: t("Error"),
          message: t("orderDeliveryFailed"),
          type: "danger",
        });
      }
    } catch (error) {
      setErrorMessage(t("orderDeliveryFailed"));
    } finally {
    }
  };

  const cancelOrderHandler = async (orderId) => {
    try {
      await httpDeleteOrder(orderId);
      notify({
        title: "",
        message: t("cancellationSuccessful"),
        type: "primary",
      });
      fetchOrders();
      decrementCount(1);
    } catch (error) {
      notify({
        title: t("Error"),
        message: t("cancellationFailed"),
        type: "danger",
      });
    } finally {
    }
  };

  useEffect(() => {
    if (!!productNumber === true && !!productionLine === true) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [productNumber, productionLine]);

  //Pagination
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOffsetChange = (offset) => {
    setItemsPerPage(offset);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="container-background mb-5">
        {errorMessage && (
          <Alert color="danger" className="text-center mt-5 item-padding">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert color="success" className="text-center mt-5 item-padding">
            {successMessage}
          </Alert>
        )}
        <Row className="item-padding">
          <Col className="responsive-col">
            <Typography bold>{t("serialNumber")}</Typography>
            <Input
              type="text"
              placeholder="0000"
              value={productNumber}
              onChange={(e) => setProductNumber(e.target.value)}
            />
          </Col>
          <Col className="responsive-col">
            {/* <Row>
              <Col className="responsive-col"> */}
            <Typography bold>{t("productionLine")}</Typography>
            <Input
              type="text"
              placeholder="000"
              value={productionLine}
              onChange={(e) => setProductionLine(e.target.value)}
            />
          </Col>
          <Col className="responsive-col">
            <Typography bold>{t("Deliver")}</Typography>
            <Select
              options={deliveryTimes}
              value={priority}
              getOptionValue={(option) => `${option["id"]}`}
              getOptionLabel={(option) => `${option["time"]}`}
              onChange={setPriority}
            />
          </Col>
          {/* </Row>
          </Col> */}
        </Row>
        <Row className="mt-2 item-padding">
          <Col className="responsive-col">
            <Button
              className="w-100"
              disabled={disableButton || isCreatingOrder}
              onClick={addOrderHandler}
            >
              <Typography bold className="text-uppercase " variant="h3">
                {isCreatingOrder ? (
                  <div className="d-flex justify-content-center inline text-center">
                    <Spinner size="md" className="me-2" /> {t("Loading")}...
                  </div>
                ) : (
                  t("order")
                )}
              </Typography>
            </Button>
          </Col>
        </Row>

        <Row className="mt-5 mb-3 d-flex justify-content-center item-padding">
          <Col>
            <Typography variant="h2" bold>
              {t("Orders")}
            </Typography>
          </Col>
        </Row>

        <Row>
          <Col>
            {ordersList && ordersList.length > 0 && (
              <SortableTable data={ordersList} columns={orderColumns} />
            )}
          </Col>
        </Row>

        <Row className="mt-5 mb-3 d-flex justify-content-center">
          <Typography variant="h2" bold className="mb-2 item-padding">
            {t("deliveredOrders")}
          </Typography>

          <Row className="mb-2">
            <Col className="d-flex">
              <Typography className="mx-2" bold style={{ color: "#E6D200" }}>
                <i className="fas fa-circle"></i>&nbsp; {t("noRush")}
              </Typography>
              <Typography className="mx-2" bold style={{ color: "green" }}>
                <i className="fas fa-circle"></i>&nbsp; {t("normal")}
              </Typography>
              <Typography className="mx-2" bold style={{ color: "red" }}>
                <i className="fas fa-circle"></i>&nbsp; {t("urgent")}
              </Typography>
            </Col>
          </Row>
          <Row>
            {deliveredList && deliveredList.length > 0 && (
              <>
                <SortableTable
                  columns={deliveredColumns}
                  data={deliveredList}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  onOffsetChange={handleOffsetChange}
                />
              </>
            )}
          </Row>
        </Row>
      </div>
    </>
  );
};
export default ProductionView;
