import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// nodejs library to set properties for components
import PropTypes from "prop-types";

import { useAuthContext } from "contexts/AuthContext";
import Logo from "assets/images/WhiteLogo.png";
import NavbarLinks from "./NavbarLinks";
// import fiFlag from "assets/images/icons/flags/fi_32.png";
// import gbFlag from "assets/images/icons/flags/gb_32.png";
// import esFlag from "assets/images/icons/flags/es_32.png";

import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";

function AdminNavbar({
  history,
  myUser,
  theme,
  sidenavOpen,
  topNavOpen,
  toggleTopNav,
}) {
  const { isAuthenticated, logout } = useAuthContext();
  const { t } = useTranslation();

  // function that on mobile devices makes the search open
  // const openSearch = () => {
  //   document.body.classList.add("g-navbar-search-showing");
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-showing");
  //     document.body.classList.add("g-navbar-search-show");
  //   }, 150);
  //   setTimeout(function () {
  //     document.body.classList.add("g-navbar-search-shown");
  //   }, 300);
  // };
  // // function that on mobile devices makes the search close
  // const closeSearch = () => {
  //   document.body.classList.remove("g-navbar-search-shown");
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-show");
  //     document.body.classList.add("g-navbar-search-hiding");
  //   }, 150);
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-hiding");
  //     document.body.classList.add("g-navbar-search-hidden");
  //   }, 300);
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-hidden");
  //   }, 500);
  // };

  // const handleMyAccount = () => {
  //   history.push("/dashboard/myaccount");
  // };

  const handleLogout = () => {
    logout();
  };

  // const handleRegister = () => {
  //   history.push("/auth/register");
  // };

  // const handleLogin = () => {
  //   history.push("/auth/login");
  // };

  // const handleRefresh = () => {
  //   window.location.href = "/";
  // };

  // const exampleClickHandler = (id) => {
  //   history.push(`/example:${id}`);
  // };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <>
      <div className="saoy_main-background no-print">
        <Navbar expand="md" className={"saoy_main-background"}>
          <NavbarBrand href="/">
            <img src={Logo} style={{ height: "64px" }} alt="logo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            {isAuthenticated ? (
              <Nav className="ms-auto" navbar>
                <NavbarLinks />
                {/* <NavItem>
                  <Button 
                  className="text-white mx-1" 
                  variant="dark"
                  onClick={handleMyAccount}
                  >
                  {t("My account")}
                  </Button>
                  </NavItem> */}

                {/* <NavItem>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => changeLanguage("es")}
                  >
                    <img
                      src={esFlag}
                      alt="Change language to estonia"
                      width={"auto"}
                      height={28}
                    />
                  </div>
                </NavItem>
                <NavItem>
                  <div
                    className="mx-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => changeLanguage("en")}
                  >
                    <img
                      src={gbFlag}
                      alt="Change language to english"
                      width={"auto"}
                      height={28}
                    />
                  </div>
                </NavItem>
                <NavItem>
                  <div
                    className="me-5"
                    style={{ cursor: "pointer" }}
                    onClick={() => changeLanguage("fi")}
                  >
                    <img
                      src={fiFlag}
                      alt="Change language to finnish"
                      width={"auto"}
                      height={28}
                    />
                  </div>
                </NavItem> */}
                <div className="user-info">
                  <span className="user-name">{myUser.email}</span>
                  <div className="logout-button" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt"></i> {t("Logout")}
                  </div>
                </div>
              </Nav>
            ) : (
              <></>
              // Flags
              // <Nav navbar className="ms-auto">
              //   <NavItem>
              //     <div
              //       style={{ cursor: "pointer" }}
              //       onClick={() => changeLanguage("es")}
              //     >
              //       <img
              //         src={esFlag}
              //         alt="Change language to estonia"
              //         width={"auto"}
              //         height={28}
              //       />
              //     </div>
              //   </NavItem>
              //   <NavItem>
              //     <div
              //       className="mx-3"
              //       style={{ cursor: "pointer" }}
              //       onClick={() => changeLanguage("en")}
              //     >
              //       <img
              //         src={gbFlag}
              //         alt="Change language to english"
              //         width={"auto"}
              //         height={28}
              //       />
              //     </div>
              //   </NavItem>
              //   <NavItem>
              //     <div
              //       className="me-5"
              //       style={{ cursor: "pointer" }}
              //       onClick={() => changeLanguage("fi")}
              //     >
              //       <img
              //         src={fiFlag}
              //         alt="Change language to finnish"
              //         width={"auto"}
              //         height={28}
              //       />
              //     </div>
              //   </NavItem>
              // </Nav>
              // <Nav className="ms-auto" navbar>
              //   <NavbarLinks />
              //   <NavItem>
              //     <Button
              //       className="text-white mx-1 my-1"
              //       variant="dark"
              //       onClick={handleRegister}
              //     >
              //       {t("Register")}
              //     </Button>
              //   </NavItem>
              //   <NavItem>
              //     <Button
              //       className="text-white mx-1 my-1"
              //       variant="dark"
              //       onClick={handleLogin}
              //     >
              //       {t("Log in")}
              //     </Button>
              //   </NavItem>
              // </Nav>
            )}
          </Collapse>
        </Navbar>
      </div>
    </>
  );
}

AdminNavbar.defaultProps = {
  // toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  // toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
