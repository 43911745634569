import http from "./api";

const url = "/products";

export const httpGetProducts = (params) => {
  console.log("httpGetProducts");
  return http({
    url: `${url}`,
    method: "GET",
    params,
  });
};

export const httpGetProduct = (params) => {
  console.log("httpGetProduct");
  return http({
    url: `${url}`,
    method: "GET",
    params,
  });
};

export const httpUpdateProduct = (id, data) => {
  return http({
    url: `${url}/${id}`,
    method: "PATCH",
    data,
  });
};

export const httpDeleteProduct = (id) => {
  return http({
    url: `${url}/${id}`,
    method: "DELETE",
  });
};

export const httpCreateStorageLocation = (data) => {
  return http({
    url: url,
    method: "POST",
    data,
  });
};
