import { action, thunk } from "easy-peasy";
import { httpGetCategories } from "services/categories";

const model = {
  objects: [],
  setObjects: action((state, payload) => {
    state.objects = [...payload];
  }),
  addObject: action((state, payload) => {
    state.objects = [
      ...state.objects,
      payload,
      // loggedIn: true,
    ];
  }),
  getObjects: thunk(async (actions, payload) => {
    const params = {
      ordering: "name",
      ...payload,
    };
    httpGetCategories(params).then(
      (response) => {
        actions.setObjects(response.data);
      },
      (error) => {}
    );
  }),

  count: 0,
  setCount: action((state, payload) => {
    state.count = payload;
  }),
};

export default model;
