import moment from "moment";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { httpGetProducts } from "services/product";
import { httpGetProduct } from "services/product";

import { useAuthContext } from "contexts/AuthContext";

import { Input, Spinner } from "reactstrap";

import { Button, Typography } from "stories/components";
import { Row } from "stories/layout";
import { useNotification } from "stories/components/Notification";
import CreateStorageLocation from "components/project/CreateStorageLocation";
import SortableTable from "components/project/SortableTable";
import DeleteModal from "components/project/DeleteModal";
import EditModal from "components/project/EditModal";
import { httpUpdateProduct } from "services/product";
import { httpDeleteProduct } from "services/product";
import PrintableTable from "components/project/PrintableTable";

const WarehouseView = () => {
  const { t } = useTranslation();
  const focusRef = useRef();
  const { notify } = useNotification();
  const { myUser } = useAuthContext();

  const [productNumber, setProductNumber] = useState("");
  const [showEmpty, setShowEmpty] = useState(false);
  const [storageLocations, setStorageLocations] = useState([]);
  const [addStorageLocationModal, setAddStorageLocationModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  const [isFetchingSpecific, setIsFetchingSpecific] = useState(false);

  const columns = [
    { key: "storage_location", label: t("storageLocation") },
    { key: "product_number", label: t("serialNumber") },
    {
      key: "added",
      label: t("added"),
      format: (date) => moment(date.added).format("DD.MM.YYYY"),
    },
    {
      key: "added_by.email",
      label: t("Added by"),
      format: (user) =>
        user?.added_by?.first_name
          ? user?.added_by?.first_name
          : user?.added_by.email,
    },
    {
      key: "actions",
      label: "",
      sortable: false,
      format: (item) =>
        myUser?.status === 10 && (
          <div className="d-flex justify-content-end">
            <Button
              className="mx-2"
              color="danger"
              onClick={() => {
                setCurrentItem(item);
                setDeleteModal(true);
              }}
            >
              <Typography className="text-white" bold>
                {t("delete")}
              </Typography>
            </Button>
            <Button
              className="mx-2"
              color="warning"
              onClick={() => {
                setCurrentItem(item);
                setEditModal(true);
              }}
            >
              <Typography className="text-white bold" bold>
                {t("edit")}
              </Typography>
            </Button>
          </div>
        ),
    },
  ];

  useEffect(() => {
    focusRef.current.focus(); //set focus to the serial number field
  }, []);

  const addLocationHandler = () => {
    setAddStorageLocationModal(!addStorageLocationModal);
  };

  const handleDelete = async () => {
    try {
      const response = await httpDeleteProduct(currentItem.id);
      if (response.status === 204) {
        handleGetStorageLocations();
        notify({
          title: "",
          message: t("productDeleted"),
          type: "success",
        });
        setDeleteModal(false);
      } else {
        notify({
          title: t("Error"),
          message: t("ErrorDeletingProduct"),
          type: "danger",
        });
        setDeleteModal(false);
      }
    } catch (error) {}
  };

  const handleSave = async (data) => {
    const dat = {
      storage_location: data.storageLocation,
      product_number: data.productNumber,
    };

    try {
      const response = await httpUpdateProduct(data.id, dat);
      if (response.status === 200) {
        handleGetStorageLocations();
        notify({
          title: "",
          message: t("productUpdated"),
          type: "success",
        });
        setEditModal(false);
      } else {
        notify({
          title: t("Error"),
          message: t("errorProductUpdate"),
          type: "danger",
        });
        setEditModal(false);
      }
    } catch (error) {}
  };

  // Data
  const searchItemHandler = (productNumber) => {
    fetchWantedLocation({ product_number: productNumber });
  };

  // Get wanted data

  const fetchWantedLocation = async (loc) => {
    setIsFetchingSpecific(true);
    try {
      const products = await httpGetProduct(loc);
      if (!products.data || products.data.length === 0) {
        notify({
          title: t("Error"),
          message: t("WarehouseLocationNotFound"),
          type: "danger",
        });
      }
      setStorageLocations(products.data);
    } catch (error) {
      console.error("ERROR:", error);
    } finally {
      setIsFetchingSpecific(false);
    }
  };

  // get all data
  const fetchStorageLocations = async () => {
    setIsFetchingAll(true);
    try {
      const response = await httpGetProducts();
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
      notify({
        title: t("Error"),
        message: t("ErrorFetchingStorage"),
        type: "danger",
      });
      return [];
    } finally {
      setIsFetchingAll(false);
    }
  };

  const handleGetStorageLocations = async () => {
    if (showEmpty) {
      const allProducts = await fetchStorageLocations();
      setStorageLocations(allProducts);
    } else {
      const allProducts = await fetchStorageLocations();
      const nonEmptyProducts = allProducts?.filter(
        (prod) => prod.product_number
      );
      setStorageLocations(nonEmptyProducts);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <div className="container-background no-print mb-5">
        <div className="item-padding">
          <div className="d-flex justify-content-end">
            <div
              className=""
              onClick={addLocationHandler}
              style={{ cursor: "pointer" }}
            >
              <Typography className="text-warning" bold>
                + {t("New warehouse location")}
              </Typography>
            </div>
          </div>
        </div>
        <div className="item-padding d-flex flex-column flex-lg-row gap-3">
          <div className="responsive-col">
            <Typography bold>{t("serialNumber")}</Typography>
            <Input
              className="w-100"
              type="text"
              placeholder="0000"
              value={productNumber}
              onChange={(event) => setProductNumber(event.target.value)}
              innerRef={focusRef}
            />
          </div>

          <div className="d-flex align-items-end ms-auto responsive-col">
            <Button
              className="responsive-btn w-100"
              disabled={!productNumber || isFetchingSpecific}
              onClick={() => searchItemHandler(productNumber)}
            >
              <Typography className="text-uppercase" variant="h3" bold>
                {isFetchingSpecific ? (
                  <div className="d-flex justify-content-center inline text-center">
                    <Spinner size="md" className="me-2" /> {t("Loading")}...
                  </div>
                ) : (
                  t("Search")
                )}
              </Typography>
            </Button>
          </div>
        </div>

        <div className="mt-3 item-padding d-flex responsive-row gap-3">
          <div className="responsive-col flex-grow-1">
            <Button
              className="responsive-btn w-100"
              onClick={handleGetStorageLocations}
              disabled={isFetchingAll}
            >
              <Typography className="text-uppercase" bold>
                {isFetchingAll ? (
                  <div className="d-flex justify-content-center align-items-center inline text-center">
                    <Spinner size="md" className="me-2" /> {t("Loading")}...
                  </div>
                ) : (
                  t("Get all")
                )}
              </Typography>
            </Button>
          </div>
          {storageLocations && storageLocations.length > 0 && (
            <div className="responsive-col flex-grow-1">
              <Button className="responsive-btn w-100" onClick={handlePrint}>
                <Typography className="text-uppercase" bold>
                  {t("print")}
                </Typography>
              </Button>
            </div>
          )}
          <div className="d-flex align-items-center flex-grow-1">
            <Input
              type="checkbox"
              value={showEmpty}
              onChange={(event) => setShowEmpty(event.target.checked)}
            />
            <Typography bold className="ms-2">
              {t("Show empty")}
            </Typography>
          </div>
        </div>

        {storageLocations && storageLocations?.length > 0 && (
          <div
            className="mt-5 mb-3"
            style={{ background: "white", paddingInline: 12 }}
          >
            <Row>
              <SortableTable columns={columns} data={storageLocations} />
            </Row>
          </div>
        )}
      </div>

      {/*  */}
      {storageLocations && storageLocations?.length > 0 && (
        <div className="hide-screen">
          <PrintableTable data={storageLocations} className="printable" />
        </div>
      )}
      {/* Modal components */}
      <CreateStorageLocation
        isOpen={addStorageLocationModal}
        toggle={addLocationHandler}
      />

      <DeleteModal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        onConfirm={handleDelete}
      />

      <EditModal
        isOpen={editModal}
        toggle={() => setEditModal(!editModal)}
        onSave={handleSave}
        initialData={currentItem}
      />
    </>
  );
};

export default WarehouseView;
