// Base views
// import Frontpage from "views/base/Frontpage/Frontpage";
import AdminView from "views/base/Warehouse/AdminView";
import LogView from "views/base/Warehouse/LogView";
import ProductionView from "views/base/Warehouse/ProductionView";
import WarehouseView from "views/base/Warehouse/WarehouseView";
import AddProductView from "views/base/Warehouse/AddProductView";
import MyAccount from "views/base/MyAccount/MyAccount";
import MyAccountPasswordChange from "views/base/MyAccountPasswordChange/MyAccountPasswordChange";
// import UsersList from "views/base/UsersList/UsersList";

// App views

// Routes visible in left navbar
const navRoutes = [
  // {
  //   path: "/frontpage",
  //   name: "Etusivu",
  //   icon: "fas fa-home text-primary",
  //   component: Frontpage,
  //   layout: "/dashboard",
  // },
  {
    path: "/addProduct",
    name: "Lisää tuote",
    icon: "fas fa-home text-primary",
    component: AddProductView,
    layout: "/dashboard",
  },
  {
    path: "/warehouse",
    name: "Varasto",
    icon: "fas fa-home text-primary",
    component: WarehouseView,
    layout: "/dashboard",
  },
  {
    path: "/production",
    name: "Tuotanto",
    icon: "fas fa-home text-primary",
    component: ProductionView,
    layout: "/dashboard",
  },
  {
    path: "/log",
    name: "Loki",
    icon: "fas fa-home text-primary",
    component: LogView,
    layout: "/dashboard",
  },
  {
    path: "/admin",
    name: "Admin",
    icon: "fas fa-home text-primary",
    component: AdminView,
    layout: "/dashboard",
  },
];

// all other usable routes
const hiddenRoutes = [
  {
    path: "/myaccount/password",
    name: "Omat tiedot salasananvaihto",
    icon: "fas fa-user text-primary",
    component: MyAccountPasswordChange,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/myaccount",
    name: "Omat tiedot",
    icon: "fas fa-user text-primary",
    component: MyAccount,
    layout: "/dashboard",
    invisible: true,
  },
];

const routes = [...navRoutes, ...hiddenRoutes];

export default routes;

/*
Example: Collapse

{
  collapse: true,
  name: "Tuotteet",
  icon: "fas fa-box-open text-primary",
  state: "productsCollapse",
  layout: "/dashboard",
  views: [
    {
      path: "/products/new",
      name: "Luo tuote",
      miniName: "PN",
      component: ProductEdit,
      layout: "/dashboard",
    },
    {
      path: "/products/list",
      name: "Tuotteet",
      miniName: "A",
      component: ProductsList,
      layout: "/dashboard",
    },
  ],
},

*/
