import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from "reactstrap";
import { Badge } from "./Badge"
import './IconButton.css';

export const IconButton = ({ iconName, size, className, color, children, badgeColor, badgeValue, ...rest }) => {
  
  const getIconClass = useCallback(() => {
    if (iconName.startsWith("ni")) {
      return `ni ni-${iconName}`
    }
    else {
      return `fas fa-${iconName}`
    }
  }, [iconName])
  
  return (
    <BootstrapButton
      className={`btn-icon icon_button_${size} ${className ? className : ""}`}
      type="button"
      color={color}
      size={size}
      {...rest}
    >
      <span className="btn-inner--icon">
        <i 
          className={getIconClass()}
        />
      </span>
      { badgeValue && (<Badge style={{marginLeft: 0, top: "-80%", right: "-12px"}} color={badgeColor} type="floating">{badgeValue}</Badge>)}
      {children}
    </BootstrapButton>
  );
};

IconButton.propTypes = {
  badgeColor: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'normal', 'lg','xl']),
};

IconButton.defaultProps = {
  badgeColor: "default",
  badgeValue: null,
  color: 'default',
  iconName: 'app',
  size: 'normal',
};
