import { useState } from "react";

import { httpCreateStorageLocation } from "services/product";

import {
  Input,
  Button,
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const CreateStorageLocation = ({ isOpen, toggle }) => {
  const { t } = useTranslation();
  const [newLocation, setNewLocation] = useState("");
  // const [productNumber, setProductNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateLocation = async () => {
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    try {
      await httpCreateStorageLocation({
        storage_location: newLocation,
        product_number: null,
      });
      setSuccessMessage(t("newStorageLocationCreated"));
      setNewLocation("");
    } catch (error) {
      console.error("Error creating storage location:", error);
      setErrorMessage(t("storageLocationCreationFailed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="custom-modal">
      <ModalHeader toggle={toggle}>
        <h3>{t("createNewStorageLocation")}</h3>
      </ModalHeader>
      <ModalBody>
        <Input
          className="mb-3"
          type="text"
          placeholder={t("newWarehouseLocation")}
          value={newLocation}
          onChange={(event) => setNewLocation(event.target.value)}
        />

        {successMessage && (
          <Alert color="success" className="infoMessage">
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert color="danger" className="infoMessage">
            {errorMessage}
          </Alert>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className="text-white text-bold"
          color="primary"
          onClick={handleCreateLocation}
          disabled={loading || !newLocation}
        >
          {loading ? (
            <Spinner size="sm" color="light" />
          ) : (
            t("newWarehouseLocation")
          )}
        </Button>
        <Button
          color="secondary"
          onClick={toggle}
          className="text-white text-bold"
        >
          {t("cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateStorageLocation;
