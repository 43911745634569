import React from 'react';
import PropTypes from 'prop-types';
import './typography.css';

/*
  if (component) {
    return React.createElement(
      component,
      {
        className: classNames([
          classes["typography-root"],
          classes[`typography-${variant}`],
          className ? className : "",
        ]),
        ...props,
      },
      [children]
    );
  }
  */

export const Typography = ({ variant, bold, children, className, color, inline, ...rest }) => {
  
  let classNameCommon = `mb-0 ${color ? color : ""} ${className ? className : ""} ${bold ? "header-bold" : ""} ${inline ? "text-inline" : ""}`;
  
  if (variant === 'h1') {
    return (
      <h1
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </h1>
    )
  }
  else if (variant === 'h2') {
    return (
      <h2
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </h2>
    )
  }
  else if (variant === 'h3') {
    return (
      <h3
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </h3>
    )
  }
  else if (variant === 'h4') {
    return (
      <h4
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </h4>
    )
  }
  else if (variant === 'h5') {
    return (
      <h5
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </h5>
    )
  }
  else if (variant === 'h6') {
    return (
      <h6
        className={`${classNameCommon}`}
        {...rest}
      >
      {children}
      </h6>
    )
  }
  else if (variant === 'display1') {
    return (
      <h1
        className={`${classNameCommon} display-1`}
        {...rest}
      >
      {children}
      </h1>
    )
  }
  else if (variant === 'display2') {
    return (
      <h2
        className={`${classNameCommon} display-2`}
        {...rest}
      >
      {children}
      </h2>
    )
  }
  else if (variant === 'display3') {
    return (
      <h3
        className={`${classNameCommon} display-3`}
        {...rest}
      >
      {children}
      </h3>
    )
  }
  else if (variant === 'display4') {
    return (
      <h4
        className={`${classNameCommon} display-4`}
        {...rest}
      >
      {children}
      </h4>
    )
  }
  else if (variant === 'display5') {
    return (
      <h5
        className={`${classNameCommon} display-5`}
        {...rest}
      >
      {children}
      </h5>
    )
  }
  else if (variant === 'display6') {
    return (
      <h6
        className={`${classNameCommon} display-6`}
        {...rest}
      >
      {children}
      </h6>
    )
  }
  else if (variant === 'lead') {
    return (
      <p
        className={`${classNameCommon} lead`}
        {...rest}
      >
      {children}
      </p>
    )
  }
  else if (variant === 'small') {
    return (
      <p
        className={`${classNameCommon} small`}
        {...rest}
      >
      {children}
      </p>
    )
  }
  return (
    <p 
      className={`${classNameCommon}`}
      {...rest}
    >
    {children}
    </p>
  )
};

Typography.propTypes = {
  bold: PropTypes.bool,
  variant: PropTypes.oneOf(['h1','h2','h3','h4','h5','h6','display1','display2','display3','display4','display5','display6','p','lead']),
  color: PropTypes.oneOf(['text-default','text-muted','text-primary','text-info','text-success','text-warning','text-danger','text-dark']),
  inline: PropTypes.bool,
  // backgroundColor: PropTypes.string,
  // size: PropTypes.oneOf(['sm', 'normal', 'lg']),
  // label: PropTypes.string.isRequired,
  // onClick: PropTypes.func,
};

Typography.defaultProps = {
  variant: 'p',
  bold: false,
  inline: false,
  // color: 'text-default',
};
