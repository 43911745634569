import React from "react";
import PropTypes from "prop-types";
import { Badge as BootstrapBadge } from "reactstrap";

import "./Badge.css";

export const Badge = ({ type, color, size, className, children, ...rest }) => {
  let sizeClass = "";
  if (size && size === "small") {
    sizeClass = "badge-sm";
  } else if (size && size === "medium") {
    sizeClass = "badge-md";
  } else if (size && size === "large") {
    sizeClass = "badge-lg";
  }

  let typeClass = "";
  if (type && type === "floating") {
    typeClass = "badge-circle badge-floating border-white";
  } else if (type && type === "pad") {
    typeClass = "badge-circle badge-pad border-white";
  }
  let classNames = `${className ? className : ""} ${sizeClass} ${typeClass}`;

  return (
    <BootstrapBadge className={`${classNames}`} color={color} {...rest} pill>
      {children}
    </BootstrapBadge>
  );
};

Badge.propTypes = {
  size: PropTypes.oneOf(["default", "medium", "large"]),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "default",
    "info",
    "success",
    "danger",
    "warning",
  ]),
  type: PropTypes.oneOf(["normal", "floating", "pad"]),
};

Badge.defaultProps = {
  size: "medium",
  color: "primary",
  type: "normal",
};
