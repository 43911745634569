import { action, thunk } from "easy-peasy";

const model = {
  cookieValue: null,
  setCookieValue: action((state, payload) => {
    state.cookieValue = payload;
  }),
  getLocalStorage: thunk(async (actions, payload) => {
    const sk_cookies_enabled = localStorage.getItem("sk_cookies_enabled");
    actions.setCookieValue(sk_cookies_enabled);
  }),
  setLocalStorage: thunk(async (actions, payload) => {
    localStorage.setItem("sk_cookies_enabled", payload);
    actions.setCookieValue(payload);
  }),
};

export default model;
