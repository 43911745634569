import { action, thunk } from 'easy-peasy';
import { httpLogin } from 'services/auth'

const model = {
  session: {
    loggedIn: false,
  },
  saveLogin: action((state, payload) => {
    state.session = {
      ...state.session,
      ...payload,
      loggedIn: true,
    }
  }),
  login: thunk(async (actions, payload) => {
    const { username, password } = payload;
    httpLogin(username, password).then(res => {
      actions.saveLogin(res);
    }, error => {
      console.log("login:error",error);
    })
    
  }),
};

export default model;