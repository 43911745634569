import React from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";

import { Typography } from "stories/components";

import "./Checkbox.css";

export const Checkbox = ({
  id = "please_provide_unique_id",
  checked = false,
  className,
  classNameDiv,
  label,
  errorText,
  ...rest
}) => {
  return (
    <div className={`custom-control custom-checkbox ${classNameDiv}`}>
      <input
        id={id}
        checked={checked}
        className={`custom-control-input ${className ? className : ""}`}
        type="checkbox"
        {...rest}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
      {errorText && (
        <Typography className="text-danger">{errorText}</Typography>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  classNameDiv: "",
};

/** Example

<Checkbox
  {...getFormikInputProps("active")}
  label="Aktiivinen"
  checked={values?.active}
/>

EXAMPLE 2

const [terms, setTerms] = useState(false)
  
const handleChange = (e) => {
  setTerms(e?.target?.checked)
}
  
<Checkbox
  id="subscribe_agree_terms"
  name="agree_terms"
  checked={terms}
  onChange={(e) => handleChange(e)}
  value="agree_terms"
  required
  label={<><Link isExternal={true} href="https://www.sarkain.com/en/terms/">{t('I agree to the terms and conditions')}</Link></>}
/>

**/
