import http from "./api";

const url = "/orders";

export const httpGetOrders = () => {
  return http({
    url: url,
    method: "GET",
  });
};

export const httpCreateOrder = (data) => {
  return http({
    url: url,
    method: "POST",
    data,
  });
};

export const httpUpdateOrder = (id, data) => {
  return http({
    url: `${url}/${id}`,
    method: "PATCH",
    data,
  });
};

export const httpDeleteOrder = (id) => {
  return http({
    url: `${url}/${id}`,
    method: "DELETE",
  });
};
