import React, { useEffect, useState } from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/base/Navbars/AdminNavbar.js";
// import Sidebar from "components/base/Sidebar/Sidebar.js";
// import Footer from "components/base/Footers/Footer.js";

import { PageWrapper } from "components/base/Page";
import { useAuthContext } from "contexts/AuthContext";
import routesAdmin from "routes_dashboard.js";
import { IconButton } from "stories/components";
import Header from "components/project/Header";
import { useStoreActions, useStoreState } from "easy-peasy";

const showTopNavigationToggleButton = false;

function Dashboard({ history, sidenavAlwaysOpen }) {
  const { myUser } = useAuthContext();
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [router, setRouter] = useState();
  const orderCount = useStoreState((state) => state.orderCount.count);

  const fetchOrderCount = useStoreActions(
    (actions) => actions.orderCount.fetchOrderCount
  );

  useEffect(() => {
    fetchOrderCount(); // Fetch order count when component mounts
  }, [fetchOrderCount]);

  useEffect(() => {
    if (document?.documentElement) {
      document.documentElement.scrollTop = 0;
    }
    if (document?.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContentRef?.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [location]);

  useEffect(() => {
    setRouter(routesAdmin);
  }, [myUser]);

  // Top navigation
  const [topNavOpen, setTopNavOpen] = useState(true);

  const toggleTopNav = () => {
    setTopNavOpen((s) => !s);
  };

  const getRoutes = (_router) => {
    if (!_router) {
      return;
    }

    // Returns only right routes, by user permissions and layout
    return _router.filter((route, key) => {
      if (route?.layout === "/dashboard" || route?.layout === "/channel") {
        if (route?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    });
  };

  const listRoutes = (_router) => {
    if (!_router) {
      return;
    }
    // Construct Route components
    const filteredRoutes = getRoutes(_router);

    return filteredRoutes.map((prop, key) => {
      if (!prop.component && !prop.collapse) {
        return null;
      }
      if (prop.collapse) {
        return listRoutes(prop.views);
      }
      if (prop.layout === "/dashboard" || prop.layout === "/channel") {
        if (prop?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return (
              <Route
                exact={prop.exact ? prop.exact : undefined}
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        } else {
          return (
            <Route
              exact={prop.exact ? prop.exact : undefined}
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    if (!router) {
      return "";
    }
    for (let i = 0; i < router.length; i++) {
      if (location.pathname.indexOf(router[i].layout + router[i].path) !== -1) {
        return router[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (sidenavAlwaysOpen) {
      return;
    }
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  // const getNavbarTheme = () => {
  //   return location.pathname.indexOf("admin/alternative-dashboard") === -1
  //     ? "light"
  //     : "dark";
  // };

  if (router) {
    return (
      <>
        {/*
        <Sidebar
          routes={getRoutes(router)}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          sidenavAlwaysOpen={sidenavAlwaysOpen}
          logo={{
            innerLink: "/dashboard/frontpage",
            imgSrc: require("assets/images/logo_nav.png").default,
            imgAlt: "investime",
          }}
          rtlActive={false}
        />
      */}
        <div className="main-content" ref={mainContentRef}>
          <AdminNavbar
            history={history}
            theme={"dark"}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
            myUser={myUser}
            topNavOpen={topNavOpen}
            toggleTopNav={toggleTopNav}
          />
          {showTopNavigationToggleButton && (
            <div
              style={{
                position: "absolute",
                right: "4px",
                top: topNavOpen ? "0px" : "-12px",
              }}
            >
              <IconButton
                color="secondary"
                size="lg"
                iconName={topNavOpen ? "times" : "angle-down"}
                onClick={toggleTopNav}
              />
            </div>
          )}
          <PageWrapper>
            <Header myUser={myUser} orderCount={orderCount} />
            <Switch>
              {listRoutes(router)}
              <Redirect from="*" to="/dashboard/addProduct" />
            </Switch>
          </PageWrapper>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
  return <></>;
}

Dashboard.defaultProps = {
  sidenavAlwaysOpen: true,
};

export default Dashboard;
