import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { PageWrapper, PageHeader } from "components/base/Page"

import { useNotification } from "stories/components/Notification"
import { httpGetMyUser, httpUpdateUser } from "services/users"
import { httpAddEvent, httpGetEvents } from 'services/events'
import { useAuthContext }from "contexts/AuthContext"

import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import tab from 'assets/images/icons/common/circle-fill.svg'
import { Breadcrumb } from "stories/components";
import { BreadcrumbItem } from "stories/components";
import { Link } from "stories/components";

require('yup-phone');


const datesAreOnSameDay = (first, second) => {
  if (
    moment(first).format('l') === moment(second).format('l')
  )
  return true
}

const MyAccount = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm
   } = props;
   
   const {
      history,
      t,
      date,
      setDate,
      events,
      setEvents,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/users/list");
  }
  
  const handleDelete = () => {
    const result = window.confirm("Haluatko varmasti poistaa?");
    if (result) {
      /*
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
      */
    }
  }

  const timeList = events.map((d) => (d.start_time))

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      if (timeList.find(dDate => datesAreOnSameDay(dDate, date))) {
        return <img className="saoy_calender_tab" src={tab} alt="content"  />
      }
    }
  }

  const submitHandler = () => {
    handleSubmit()
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("first_name")}
                label={t("Etunimi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("last_name")}
                label="Sukunimi"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("email")}
                label="Sähköposti"
                type="email"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("username")}
                label="Käyttäjätunnus"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("phone")}
                label="Puhelin"
              />
            </Col>
          </Row>
          
          {/*
          <Row>
            <Col className="mb-3">
              <Checkbox
                {...getFormikInputProps("is_staff")}
                label="Admin"
                checked={values?.is_staff}
              />
            </Col>
          </Row>
          */}
          
          {/*
          <Row>
            <Col className="mb-3">
              <Checkbox
                {...getFormikInputProps("active")}
                label="Aktiivinen"
                checked={values?.active}
              />
            </Col>
          </Row>
          */}
          
          <Row>
            <Col className="mb-3">
              <Button onClick={handleCancel} variant="danger">Peruuta</Button>
            </Col>
            <Col className="mb-3 text-center">
            {/* <Button onClick={handleDelete} variant="danger">Poista</Button> */}
            </Col>
            <Col className="mb-3 text-right">
              <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
              { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
            </Col>
          </Row>
      
        </Col>
        
        <Col></Col>
        <Col>
          <Calendar 
            tileContent={tileContent}
            onChange={setDate}
            value={date}
          />
        
        <Row className="mt-1">
          <Col className="pl-0">
            <Container className="bg-default border p-3">
              <Row>
                <Col>
                  <Typography variant={'h3'}>Valitse päivä</Typography>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <DatePicker className="w-100"
                    selected={date} 
                    onChange={setDate} 
                    dateFormat="dd.MM.yyyy"
                  />
                </Col>
              </Row>
              <Row>
              <Button className="mt-4 btn btn-block" variant="success" onClick={submitHandler}>LISÄÄ</Button>
              </Row>
            </Container>
          </Col>
        </Row>
        </Col>
      
      
      </Row>
      
      
    </>
  );
}

const defaultValues = {
  id: null,
  name: '',
  // activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const MyAccountFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        first_name: Yup.string().required(required).nullable(),
        last_name: Yup.string().required(required).nullable(),
        username: Yup.string().required(required).nullable(),
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        phone: Yup.string().phone("FI", false,t('Puhelinnumero tulee olla muodossa esim. +358501234567')).required(required).nullable(),
        // activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, date} = props;
      // activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      let data = {
        ...values, 
      };
      let title = 'otsikko'
      let event = {title: title, start_time: date}
      
      httpUpdateUser("me", data).then(res => {
        setSubmitting(false);
        props.notify({ title:t("Käyttäjä"), message:t("Päivitetty onnistuneesti")})
        
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })

      httpAddEvent(event).then(res => {
        setSubmitting(false);
        props.notify({ title:t("Kalenteri"), message:t("Päivitetty onnistuneesti")})
      }, error => {
        setSubmitting(false);
        if (error?.event?.detail) {
          setErrors({
            general: error?.event?.detail,
          })
        }
        else {
          setErrors({
            general: t("Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })      
      
    },
    displayName: "BasicForm"
  
  })(MyAccount)
    
    
const MyAccountView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser, getMyUser } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const [date, setDate] = useState(new Date())
  const [events, setEvents] = useState([])
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"

  useEffect(() => {
    setLoading(true)
    httpGetMyUser().then(res => {
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
      setLoading(false)
    })

    httpGetEvents().then(res => {
      setEvents(res?.data)
    })
  }, [])
  
  const handleChangePassword = () => {
    history.push("/dashboard/myaccount/password");
  }
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      {/* <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          {t('Omat tiedot')}
        </BreadcrumbItem>
      </Breadcrumb> */}


      <div className="d-flex justify-content-between my-2">
        <div>
          <PageHeader title={t("Omat tiedot")}></PageHeader>
        </div>
        <div>
        <Button onClick={handleChangePassword}>{t('Vaihda salasana')}</Button>
        </div>
      </div>
      <MyAccountFormik 
        history={history} 
        t={t} 
        preSetValues={preSetValues} 
        notify={notify} 
        date={date}
        setDate={setDate}
        events={events}
        setEvents={setEvents}
      />
    </>
  )
  

}
  

export default MyAccountView;
