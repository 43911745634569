import React, { useState } from "react";
import PropTypes from "prop-types";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  onOffsetChange,
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 7;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 4) {
        pages.push(1, 2, 3, 4, 5, "...", totalPages);
      } else if (currentPage >= totalPages - 3) {
        pages.push(
          1,
          "...",
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pages.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return pages;
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    onOffsetChange(value);
  };

  return (
    <div className="pagination-container">
      <div className="pagination-controls">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          <i className="fas fa-chevron-left"></i>
        </button>

        {getPageNumbers().map((page, index) => (
          <button
            key={index}
            onClick={() => onPageChange(page)}
            className={`pagination-button ${
              currentPage === page ? "active" : ""
            }`}
            disabled={page === "..."}
          >
            {page}
          </button>
        ))}

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>

      <div className="items-per-page-controls">
        {[10, 25, 50, 100].map((value) => (
          <button
            key={value}
            className={`items-per-page-button ${
              itemsPerPage === value ? "active" : ""
            }`}
            onClick={() => handleItemsPerPageChange(value)}
          >
            {value}
          </button>
        ))}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onOffsetChange: PropTypes.func.isRequired,
};

export default Pagination;
