import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

import { useNotification } from "stories/components/Notification";
import { Typography } from "stories/components";
import { httpUpdateUser } from "services/users";

const RoleChange = ({ user, show, toggle, role, fetchUsers }) => {
  const { t } = useTranslation();
  const { notify } = useNotification();

  console.log("user", user);
  console.log("role", role);

  const handleRoleChange = async () => {
    const data = { status: role.id };
    try {
      const response = await httpUpdateUser(user.id, data);
      if (response.status === 200) {
        notify({
          title: "",
          message: t("Käyttäjän rooli vaihdettu"),
          type: "primary",
        });
        toggle();
        fetchUsers();
      } else {
        notify({
          title: t("Error"),
          message: t("Roolin vaihdossa"),
          type: "danger",
        });
        toggle();
      }
    } catch (error) {}
  };

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Typography variant="h3">{t("roleChange")}</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("confirmRoleChange")}</Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="danger" onClick={handleRoleChange}>
          <Typography bold className="text-white">
            {t("change")}
          </Typography>
        </Button>

        <Button variant="warning" onClick={toggle}>
          <Typography bold className="text-white">
            {t("cancel")}
          </Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RoleChange;
