import { httpDeleteUser } from "services/users";

import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

import { useNotification } from "stories/components/Notification";
import { Typography } from "stories/components";

const DeleteUser = ({ user, show, toggle, fetchUsers }) => {
  const { t } = useTranslation();
  const { notify } = useNotification();

  const handleDeleteUser = async () => {
    try {
      console.log("user", user.id);
      const response = await httpDeleteUser(user.id);

      if (response.status === 200 || response.status === 204) {
        notify({
          title: "",
          message: t("userDeletedSuccessfully"),
          type: "primary",
        });
        await fetchUsers();
        toggle();
      } else {
        notify({
          title: t("Error"),
          message: t("userDeletionError"),
          type: "danger",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("confirmUserDeletion")} {user?.email}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="success" onClick={handleDeleteUser}>
          <Typography bold className="text-white">
            {t("deleteUser")}
          </Typography>
        </Button>

        <Button variant="danger" onClick={toggle}>
          <Typography bold className="text-white">
            {t("Cancel")}
          </Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUser;
