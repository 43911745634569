import React from "react";
import { CardBody as BootstrapCardBody } from "reactstrap";
import "./Container.css";

export const CardBody = ({ children, ...rest }) => {
  return <BootstrapCardBody {...rest}>{children}</BootstrapCardBody>;
};

CardBody.propTypes = {};

CardBody.defaultProps = {};
