import http from "./api";

const url = "/token";

export const httpLogin = (email, password) => {
  console.log("httpLogin");
  return http({
    url: url,
    method: "POST",
    data: {
      email,
      password,
    },
  });
};
