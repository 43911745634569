import React from "react";
import { Container } from "stories/layout";

const PageWrapper = (props) => {
  const { children } = props;

  // fluid

  return <Container className="page page_wrapper mt-3">{children}</Container>;
};

export default PageWrapper;
