import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Typography } from "stories/components";

const DeleteModal = ({ isOpen, toggle, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <Typography variant="h3">{t("confirmDeletion")}</Typography>
      </ModalHeader>
      <ModalBody>{t("confirmDeleteItem")}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirm}>
          <Typography bold className="text-white">
            {t("delete")}
          </Typography>
        </Button>
        <Button color="warning" onClick={toggle}>
          <Typography bold className="text-white">
            {t("cancel")}
          </Typography>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
