import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Typography, Badge } from "stories/components";
import { useTranslation } from "react-i18next";

// Styled-components
const HeaderContainer = styled.div`
  width: 100%;
  border-radius: 25px 25px 0 0;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }

  @media print {
    display: none;
  }
`;

const Tab = styled.div`
  text-align: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  color: ${(props) => (props.$active ? `#fda014` : "#333333")};
  background-color: transparent;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
  flex-grow: 1; /* Jokainen tab ottaa yhtä paljon tilaa */
  margin: 0 0.5rem;
  max-width: 200px;

  &:hover {
    background-color: ${(props) => (props.$active ? "#e2e6ea" : "#e2e6ea")};
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    max-width: 80px;
  }
`;

const Icon = styled.i`
  margin-bottom: 0.5rem;
  transition: transform 0.3s;

  ${(props) =>
    props.$active &&
    `
    transform: scale(1.2);
  `}

  ${Tab}:hover & {
    transform: scale(1.1);
  }
`;

const Header = ({ myUser, orderCount }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const tabs = [
    {
      path: "/dashboard/addProduct",
      icon: "fas fa-cart-plus",
      label: t("Add product"),
    },
    {
      path: "/dashboard/warehouse",
      icon: "fas fa-warehouse",
      label: t("Warehouse"),
    },
    {
      path: "/dashboard/production",
      icon: "fas fa-cogs",
      label: (
        <>
          {t("Production")}&nbsp;
          <Badge color="danger">{orderCount}</Badge>
        </>
      ),
    },
    { path: "/dashboard/log", icon: "far fa-calendar-alt", label: t("Log") },
    {
      path: "/dashboard/admin",
      icon: "fas fa-users-cog",
      label: t("Admin"),
      condition: myUser.status === 10,
    },
  ];

  return (
    <HeaderContainer>
      {tabs.map(({ path, icon, label, condition = true }) =>
        condition ? (
          <Link to={path} key={path} style={{ flex: 1 }}>
            <Tab $active={location.pathname === path}>
              <Icon className={icon} $active={location.pathname === path} />
              <Typography
                bold
                style={{
                  color: location.pathname === path ? "#fda014" : "#333333",
                }}
              >
                {label}
              </Typography>
            </Tab>
          </Link>
        ) : null
      )}
    </HeaderContainer>
  );
};

export default Header;
