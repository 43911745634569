import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi"; // without this line it didn't work

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
// plugins styles downloaded

// Nucleo icons
import "assets/vendor/nucleo/css/nucleo.css"; // icons
import "react-notification-alert/dist/animate.css";

// Font Awesome 5 icons
import "@fortawesome/fontawesome-free/css/all.min.css";

// core styles
import "./assets/scss/custom.scss";

import "./services/i18n";

import { AppProvider } from "contexts/AppContext";
import { AuthProvider } from "contexts/AuthContext";
import { NotificationProvider } from "stories/components/Notification";
import history from "services/history";
import { StoreProvider } from "easy-peasy";
import { store } from "store/index";

import App from "./app";

moment().locale("fi");

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider store={store}>
      <AppProvider>
        <AuthProvider history={history}>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </AuthProvider>
      </AppProvider>
    </StoreProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
