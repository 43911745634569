import http from './api'

export const httpGetCategories = (params) => {
  console.log("httpGetCategories");
  return http({
    url: `/categories/` ,
    method: 'GET',
    params,
  })
}

export const httpGetCategory = (id) => {
  console.log("httpGetCategory");
  return http({
    url: `/categories/${id}` ,
    method: 'GET',
  })
}